import { Error, Loader } from '@myap/ui-library/esm/components'
import { BasicInput, ChangesSavedTextNotification } from '../../common'
import { fetchVolumeRebates, saveVolumeRebates } from '../../../actions/orders'
import { addFetchAction, removeFetchAction } from '../../../actions/app'

const mapStateToProps = state => ({
  ...state.orderVolumeRebates,
  orgId: state.user.data.selectedOrgId,
  educationPeriodCd: state.settingsEducationPeriod.selectedEducationPeriod,
})

const OPTIONS = [
  {
    label: (
      <>
        An honorarium for AP coordinator work outside of school hours during the AP exam
        administration<sup>*</sup>
      </>
    ),
    value: 'HONORARIUM',
  },
  {
    label:
      'Compensation for a consultant or part-time employee to manage or assist in the AP exam administration',
    value: 'ADMINISTRATION_COMPENSATION',
  },
  {
    label: 'Payment of off-site test center rental fees',
    value: 'RENTAL_PAYMENT',
  },
  {
    label:
      'Funds for testing equipment or supplies (e.g., handheld digital recorder(s), CD player(s), pens, pencils)',
    value: 'EQUIPMENT_FUNDING',
  },
  {
    label:
      'Funds to travel to/from AP coordinator workshops, the AP Annual Conference, or other school counselor or testing coordinator professional development events',
    value: 'TRAVEL_FUNDING',
  },
  {
    label: (
      <>
        Payment of proctors<sup>*</sup>
      </>
    ),
    value: 'PROCTOR_PAYMENT',
  },
  {
    label:
      'Funds to support upgrades or additions to school network bandwidth or WiFi capacity for administration of digital AP Exams',
    value: 'NETWORK_UPGRADE',
  },
]

const VolumeRebateTab = ({
  fetching,
  error,
  saving,
  saveError,
  saved,
  eligibleForRebate,
  paymentDeadlinePassed,
  locked = true,
  largeVolumeRebateUses = [],
  fetchVolumeRebates,
  saveVolumeRebates,
  orgId,
  educationPeriodCd,
  tabLabel,
  addFetchAction,
  removeFetchAction,
}) => {
  const [selected, setSelected] = useState(largeVolumeRebateUses)
  const [showSavedMsg, setShowSavedMsg] = useState(false)
  const hasChanges =
    selected.length === largeVolumeRebateUses.length &&
    selected.every(s => largeVolumeRebateUses.find(l => l === s))
  const rebatesEnabled = eligibleForRebate && (!paymentDeadlinePassed || !locked)

  const update = e =>
    setSelected(
      e.target.checked ? [...selected, e.target.value] : selected.filter(s => s !== e.target.value)
    )

  useEffect(() => {
    addFetchAction({
      name: 'fetchVolumeRebates',
      action: (orgId, educationPeriodCd) => fetchVolumeRebates(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchVolumeRebates')
  }, [])

  useEffect(() => {
    if (!selected.length && largeVolumeRebateUses.length) {
      setSelected(largeVolumeRebateUses)
    }
  }, [largeVolumeRebateUses])

  useEffect(() => {
    if (saved) {
      setShowSavedMsg(true)
    }
    setTimeout(() => setShowSavedMsg(false), 500)
  }, [saved])

  if (error) {
    return <Error title="Error Retrieving Data" message={error} />
  }

  if (fetching) {
    return <Loader />
  }

  return (
    <div aria-labelledby={tabLabel} role="tabpanel">
      {!eligibleForRebate ? (
        <p>
          You do not qualify for this rebate since this is offered when there are at least 150 exams
          ordered.
        </p>
      ) : locked && paymentDeadlinePassed ? (
        <p>Deadline to opt-in to large volume rebates has passed.</p>
      ) : null}
      <fieldset style={{ marginBottom: '1em' }} disabled={!rebatesEnabled}>
        <legend
          style={{
            fontSize: '1rem',
            fontFamily: 'Roboto',
            fontWeight: '400',
            borderBottom: 0,
            color: !rebatesEnabled ? '#989795' : '#505050',
          }}
        >
          Please indicate how you will be using your large volume rebate funds.
        </legend>
        {OPTIONS.map(o => (
          <BasicInput
            key={o.value}
            type="checkbox"
            style={{ marginBottom: '1em' }}
            input={{
              name: `field${o.value}`,
              value: o.value,
              checked: Boolean(selected.find(l => l === o.value)),
              onChange: update,
            }}
            label={o.label}
          />
        ))}
      </fieldset>
      {saveError ? (
        <p className="cb-error-msg" style={{ marginBottom: '1em' }} aria-live="polite">
          Error: {saveError}
        </p>
      ) : null}
      <ChangesSavedTextNotification inProp={showSavedMsg} durationIn={50} durationOut={500}>
        <p
          className="success-text"
          style={{
            lineHeight: 'normal',
            fontSize: '.875em',
          }}
        >
          <i className="cb-glyph cb-check-circle" aria-hidden={true} style={{ marginRight: 5 }} />
          <strong>Your changes have been saved.</strong>
        </p>
      </ChangesSavedTextNotification>
      <button
        type="button"
        className="btn btn-sm btn-primary"
        disabled={hasChanges || saving}
        onClick={() => saveVolumeRebates(orgId, educationPeriodCd, selected)}
      >
        Save Changes
      </button>
      <p
        style={{
          color: !rebatesEnabled ? '#989795' : '#333',
          fontSize: '.875em',
          marginTop: '2em',
          borderTop: '1px solid #ccc',
          paddingTop: '1em',
        }}
      >
        <sup>*</sup> Your school will need to deposit the funds and issue a separate check to you or
        your proctors or both, assuming responsibility for tax reporting purposes.
      </p>
    </div>
  )
}

export default connect(mapStateToProps, {
  fetchVolumeRebates,
  saveVolumeRebates,
  addFetchAction,
  removeFetchAction,
})(VolumeRebateTab)
