import * as c from '../constants/TeacherConstants'
import * as s from '../constants/SettingsConstants'

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    // FETCH
    case c.FETCH_TEACHERS_PENDING:
      return { ...state, fetching: true, fetched: false }
    case c.FETCH_TEACHERS_FULFILLED:
      return {
        ...state,
        data: { ...state.data, [payload.orgId] : { ...state.data[payload.orgId], [payload.testCd]: payload.data }},
        fetching: false,
        fetched: true,
      }
    case c.FETCH_TEACHERS_REJECTED:
      return { ...state, fetching: false, error: payload }

    case s.GRANT_TEACHER_ACCESS_TO_COURSE_FULFILLED:
      return { ...state, data: { [payload.orgId] : { ...state.data[payload.orgId], [payload.testCd]: [] }  }}

    default:
      return state
  }
}
