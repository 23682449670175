import { Loader, Error } from '../../common'
import { fetchExceptionTestingCourses, saveExceptionTestingCourses } from '../../../actions/csr'
import { getUnlockableExamWindows } from '../../../selectors/examWindows'
import { DEFAULT_REQUEST_ERROR } from '../../../constants/MessageConstants'
import { sortColumnByKey } from '../../../utils/sort'
import { TEST_ADMIN_OFFCYCLE } from '../../../constants/SettingsConstants'
import { LockUnlockComponent, BrowseItem } from '.'

import styles from '../../../assets/style/scss/csr.scss'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriod },
  } = state
  const unlockableAdminWindows = getUnlockableExamWindows(state)
  return {
    educationPeriod,
    orgId,
    unlockableAdminWindows: unlockableAdminWindows.filter(
      ({ testAdmin }) => testAdmin === TEST_ADMIN_OFFCYCLE
    ),
  }
}

export default connect(mapStateToProps)(
  ({ educationPeriod, orgId, unlockableAdminWindows, updatePanel, panel, subpanel }) => {
    const [courses, setCourses] = useState([])
    const [errorFetching, setErrorFetching] = useState(null)
    const [fetchingCourses, setFetchingCourses] = useState(true)
    const [admin, setAdmin] = useState({})

    const sortCourseData = courseData => ({
      data: sortColumnByKey(courseData, ['courseName'], ['asc']),
    })

    const prepareCourseData = () => sortCourseData(courses)

    const fetchCourses = async () => {
      await setFetchingCourses(true)
      const { data = {}, error = null } = await fetchExceptionTestingCourses(
        orgId,
        educationPeriod,
        admin?.adminWindow
      )
      setCourses(data ?? [])
      setErrorFetching(error)
      setFetchingCourses(false)
    }

    const saveData = async itemsToSave => {
      const response = await saveExceptionTestingCourses(
        itemsToSave,
        orgId,
        educationPeriod,
        admin?.adminWindow
      )
      if (response?.data) {
        await setCourses(response.data ?? [])
        return sortCourseData(response.data)
      }
      return response
    }

    useEffect(() => {
      if (admin?.adminWindow) {
        fetchCourses()
      }
    }, [admin])

    const updateSubPanel = (subpanel, title) => {
      if (subpanel === -1) {
        updatePanel(-1)
      } else {
        updatePanel(panel, title, subpanel)
      }
    }

    const selectAdminWindow = async admin => {
      const { displayName } = admin
      await setAdmin(admin)
      updateSubPanel(1, `Off-cycle Testing ${displayName}`)
    }

    if (errorFetching) {
      return (
        <div style={{ padding: '15px' }}>
          <Error
            title={`Error retrieving courses for ${admin?.displayName}`}
            message={DEFAULT_REQUEST_ERROR}
          />
        </div>
      )
    }

    if (admin?.adminWindow && fetchingCourses) {
      return <Loader />
    }

    switch (subpanel) {
      case 0:
        return (
          <div className={styles['tool-body-inner']}>
            <p className={styles['tool-body-divider']}>
              Select the window below that you wish to lock or unlock ordering for. Off-cycle test
              ordering will be active for 24 hours.
            </p>
            {unlockableAdminWindows.map(selectedAdmin => (
              <BrowseItem
                key={selectedAdmin.adminWindow}
                title={selectedAdmin.displayName}
                action={() => selectAdminWindow(selectedAdmin)}
              />
            ))}
          </div>
        )
      case 1:
        return (
          <LockUnlockComponent
            compId="exceptionTestingCourses"
            introText={`Select the courses to unlock ordering for ${admin.displayName}. ${admin.displayName} test ordering will be active for 24 hours.`}
            emptyText={`There are no courses to order exams for ${admin.displayName}.`}
            fnFetch={prepareCourseData}
            fnSave={saveData}
            itemProps={{
              itemId: 'testCd',
              itemDescription: 'courseName',
            }}
          />
        )
    }
  }
)
