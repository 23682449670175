import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import Tracking from './Tracking'
import * as c from '../../constants/ShipmentConstants'
import { numberFormat } from '../../utils/numbers'

const statusGenerator = (status, suborderUpdateDate) => {
  const formattedUpdateDate = formatDate(suborderUpdateDate, DATETIME_FORMATS.longMonthDayYear)
  switch (status) {
    case c.STATUS_PROCESSING:
      return c.PROCESSING
    case c.STATUS_SUBMITTED:
      return c.SUBMITTED
    case c.STATUS_RECEIVED:
      return `Received on ${formattedUpdateDate}`
    case c.STATUS_SHIPPED:
      return `Shipped on ${formattedUpdateDate}`
    default:
      return ''
  }
}

/**
 * Create top suborder of the CollapsableSections component.
 */
const SubOrderStatus = ({
  suborder: { status, updateDate, trackingInfo, testDayType, id, quantity },
}) => {
  return (
    <div style={{ margin: '5px 10px' }}>
      <div className="row">
        <div className="col-xs-12 col-md-6 col-lg-7">
          <div className="cb-small-font-size">
            <strong>Status: </strong>
            {statusGenerator(status, updateDate)}
          </div>
          {testDayType === c.TYPE_AP_LABELS || testDayType === c.TYPE_ALL_ADMIN ? (
            <div className="cb-small-font-size">
              <strong>Quantity: </strong>
              {numberFormat(quantity)}
            </div>
          ) : null}
        </div>

        <div className="col-xs-12 col-md-6 col-lg-5">
          <Tracking trackingInfo={trackingInfo} status={status} id={id} />
        </div>
      </div>
    </div>
  )
}

export default SubOrderStatus
