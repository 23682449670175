import CoordinatorLink from './CoordinatorLink'

const TEST_DAY_TOOLKIT_URL = 'https://testday.collegeboard.org/'

const mapStateToProps = state => {
  const {
    user: {
      data: { isCoordinator, isCSR },
    },
    status: {
      data: { examRegistrations },
    },
  } = state
  return {
    canAccess: isCoordinator && !isCSR,
    showLink: examRegistrations?.hasDigitalExams || examRegistrations?.hasHybridExams,
  }
}

const TestDayToolKitLink = ({ canAccess, showLink }) =>
  showLink ? (
    <CoordinatorLink
      linkName="Test Day Toolkit"
      linkUrl={TEST_DAY_TOOLKIT_URL}
      disabled={!canAccess}
    />
  ) : null

export default connect(mapStateToProps)(TestDayToolKitLink)
