import { useCallback } from 'react'
import {
  reduxForm,
  isValid,
  change,
  formValueSelector,
  getFormMeta,
  SubmissionError,
  isPristine,
  initialize,
} from 'redux-form'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { fetchAvailableCourses } from '../../actions/course'
import {
  Text,
  Textarea,
  Select,
  Input,
  PhoneValidation,
  EmailValidation,
  LimitedStringValidation,
  LetterValidation,
  FormCodeValidation,
  Error,
  ApIdValidation,
} from '../common'
import {
  INCIDENT_REPORT_FORM_NAME,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
  INCIDENT_REPORT_PAPER_FIELD_NAMES as PAPER_FIELDS,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES as DIGITAL_FIELDS,
  COURSES_WITH_HIDDEN_EXAM_FORM_SECTION,
} from '../../constants/IncidentReportConstants'
import {
  PAPER_EXAM,
  HYBRID_EXAM,
  DIGITAL_EXAM,
  DOMESTIC_PHONE_LENGTH,
  INTERNATIONAL_PHONE_LENGTH,
} from '../../constants/SettingsConstants'
import { getCoordinatorContactInfo, getSelectedEducationPeriod } from '../../selectors/settings'
import {
  getAllExamWindows,
  getExamFormats,
  getSortedExamWindowsForCourse,
} from '../../selectors/examWindows'
import { getAllCourses } from '../../selectors/course'
import { initializeIncidentReportData } from '../../selectors/incidentReports'
import { isEmpty } from '../../utils/common'
import {
  IncidentReportSubmitButton,
  IncidentReportSaveDraftButton,
  IncidentReportPaperIncidentFields,
  IncidentReportDigitalIncidentFields,
} from './index'
import { submitIncidentReport } from '../../actions/incidentReports'
import { openModal } from '../../actions/app'
import { convertToNumberString } from '../../utils/numbers'
import PhoneNumber from '../common/forms/PhoneNumber'

const NOT_ALLOWED_TO_SUBMIT = 'Only Coordinators may submit incident report form.'

const normalize = val => val === 'true'

const SHARED_FIELDS_LIST = [
  SHARED_FIELDS.firstName.name,
  SHARED_FIELDS.lastName.name,
  SHARED_FIELDS.phone.name,
  SHARED_FIELDS.email.name,
  SHARED_FIELDS.section.name,
  SHARED_FIELDS.testCode.name,
  SHARED_FIELDS.examDate.name,
  SHARED_FIELDS.examForm.name,
  SHARED_FIELDS.formCode.name,
  SHARED_FIELDS.interruptionMinutesInd.name,
  SHARED_FIELDS.interruptionMinutes.name,
  SHARED_FIELDS.disturbanceMinutesInd.name,
  SHARED_FIELDS.disturbanceMinutes.name,
  SHARED_FIELDS.misconductInd.name,
  SHARED_FIELDS.studentDismissedInd.name,
  SHARED_FIELDS.usedProhibitedDeviceInd.name,
  SHARED_FIELDS.removeExamMaterialsInd.name,
  SHARED_FIELDS.illnessInd.name,
  SHARED_FIELDS.otherInd.name,
  SHARED_FIELDS.lateTestingRequiredInd.name,
  SHARED_FIELDS.altExamOrderedInd.name,
  SHARED_FIELDS.fullTestCenterImpactedInd.name,
  SHARED_FIELDS.studentFirstName.name,
  SHARED_FIELDS.studentLastName.name,
  SHARED_FIELDS.studentApId.name,
  SHARED_FIELDS.students.name,
  SHARED_FIELDS.incidentDetail.name,
]

const PAPER_FIELDS_LIST = [
  PAPER_FIELDS.defectiveMatsInd.name,
  PAPER_FIELDS.multipleChoiceInd.name,
  PAPER_FIELDS.freeResponseInd.name,
  PAPER_FIELDS.shortAnswerResponseInd.name,
  PAPER_FIELDS.orangeBookletInd.name,
  PAPER_FIELDS.masterAudioCDsInd.name,
  PAPER_FIELDS.masterAudioCDs.name,
  PAPER_FIELDS.equipProbsInd.name,
  PAPER_FIELDS.shortAnsrWrngBookInd.name,
  PAPER_FIELDS.earlyOpeningSectionInd.name,
  PAPER_FIELDS.mixUpFreeResponseBooksInd.name,
  PAPER_FIELDS.wrngColorInkInd.name,
  PAPER_FIELDS.misplacedAnswersInd.name,
  PAPER_FIELDS.missingExamMaterialsInd.name,
  PAPER_FIELDS.overtimingMinutesInd.name,
  PAPER_FIELDS.overtimingMinutes.name,
  PAPER_FIELDS.undertimingMinutesInd.name,
  PAPER_FIELDS.undertimingMinutes.name,
  PAPER_FIELDS.usedInkOnAnswerSheetInd.name,
  PAPER_FIELDS.usedExtraPaperInd.name,
  PAPER_FIELDS.sharedSameAPIDInd.name,
  PAPER_FIELDS.testingOffScheduleInd.name,
]

const DIGITAL_FIELDS_LIST = [
  DIGITAL_FIELDS.batteryDiedInd.name,
  DIGITAL_FIELDS.connectionDroppedInd.name,
  DIGITAL_FIELDS.deviceFrozeOrCrashInd.name,
  DIGITAL_FIELDS.deviceDidNotMeetReqInd.name,
  DIGITAL_FIELDS.loanerDeviceIssueInd.name,
  DIGITAL_FIELDS.anotherTestAppInstalledInd.name,
  DIGITAL_FIELDS.blueBookNotInstalledInd.name,
  DIGITAL_FIELDS.examSetUpNotCompletedInd.name,
  DIGITAL_FIELDS.noAdmissionTicketInd.name,
  DIGITAL_FIELDS.studentTechIssueOther.name,
  DIGITAL_FIELDS.couldNotOpenInd.name,
  DIGITAL_FIELDS.couldNotSignInd.name,
  DIGITAL_FIELDS.testNotAvailableInd.name,
  DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name,
  DIGITAL_FIELDS.inAccurateTimingInd.name,
  DIGITAL_FIELDS.contentDisplayIssueInd.name,
  DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name,
  DIGITAL_FIELDS.answerNotSubmittedInd.name,
  DIGITAL_FIELDS.blueBookProblemOther.name,
  DIGITAL_FIELDS.staffTechIssue.name,
  DIGITAL_FIELDS.networkOutageInd.name,
  DIGITAL_FIELDS.powerOutageInd.name,
  DIGITAL_FIELDS.facilityTechIssueOther.name,
  DIGITAL_FIELDS.couldNotAccessTDTKInd.name,
  DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name,
  DIGITAL_FIELDS.studentCheckInIssueInd.name,
  DIGITAL_FIELDS.inAccurateStudentStatusInd.name,
  DIGITAL_FIELDS.testDayToolKitProblemOther.name,
]

const mapStateToProps = state => {
  const {
    user: {
      data: {
        selectedOrgId,
        isCSR,
        isETS,
        isSDP,
        isSchoolAdmin,
        isDistrictAdmin,
        isDistrictFundingAdmin,
        isDistrictSuperAdmin,
      },
    },
    availableCourses: { fetching: fetchingCourses, fetched: fetchedCourses, error: errorCourses },
    incidentReport: { submitting, draft },
  } = state
  const {
    [selectedOrgId]: {
      data: {
        schoolInformation: {
          address: { countryCode },
        },
      },
    },
  } = state.settingsOrg
  const coordinatorInfo = getCoordinatorContactInfo(state)
  const { firstName, lastName, telephone, email } = coordinatorInfo
  const selector = formValueSelector(INCIDENT_REPORT_FORM_NAME)
  const notAllowedToSubmit =
    isCSR ||
    isETS ||
    isSDP ||
    isSchoolAdmin ||
    isDistrictAdmin ||
    isDistrictFundingAdmin ||
    isDistrictSuperAdmin
  const sharedValues = selector(state, ...SHARED_FIELDS_LIST)
  const isDraft = selector(state, 'isDraft')
  const allExamWindows = getAllExamWindows(state)
  const selectedCourse = !isEmpty(sharedValues[SHARED_FIELDS.testCode.name])
    ? parseInt(sharedValues[SHARED_FIELDS.testCode.name], 10)
    : !isEmpty(draft?.exam?.[SHARED_FIELDS.testCode.name])
    ? draft.exam[SHARED_FIELDS.testCode.name]
    : undefined
  const selectedAdminWindow = !isEmpty(sharedValues[SHARED_FIELDS.examDate.name])
    ? sharedValues[SHARED_FIELDS.examDate.name]
    : !isEmpty(draft?.exam?.[SHARED_FIELDS.examDate.name])
    ? draft.exam[SHARED_FIELDS.examDate.name]
    : undefined
  const { paper, hybrid, digital, noEndOfCourseExam } = getExamFormats({
    allExamWindows,
    testCd: selectedCourse,
    adminWindow: selectedAdminWindow,
  })
  const availableExamWindows = selectedCourse
    ? getSortedExamWindowsForCourse(state, selectedCourse)
    : []
  const incidentTypeValues =
    paper || noEndOfCourseExam
      ? selector(state, ...PAPER_FIELDS_LIST)
      : selector(state, ...DIGITAL_FIELDS_LIST)
  const currentValues = {
    ...sharedValues,
    ...incidentTypeValues,
    isDraft,
  }

  const saveDisabled =
    submitting ||
    isPristine(INCIDENT_REPORT_FORM_NAME)(state) ||
    isEmpty(currentValues[SHARED_FIELDS.firstName.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.lastName.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.phone.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.email.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.section.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.testCode.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.examDate.name])

  return {
    orgId: selectedOrgId,
    notAllowedToSubmit,
    educationPeriodCd: getSelectedEducationPeriod(state),
    allExamWindows,
    firstName,
    lastName,
    telephone,
    email,
    examFormat:
      paper || noEndOfCourseExam
        ? PAPER_EXAM
        : hybrid
        ? HYBRID_EXAM
        : digital
        ? DIGITAL_EXAM
        : null,
    coordinatorInfo,
    countryCode,
    draft,
    currentValues,
    valid: isValid(INCIDENT_REPORT_FORM_NAME)(state),
    allCourses: getAllCourses({ state, apOnly: true }),
    availableExamWindows,
    fetchingCourses,
    fetchedCourses,
    errorCourses,
    saveDisabled,
    submitting,
    formFieldsMeta: getFormMeta(INCIDENT_REPORT_FORM_NAME)(state),
    [`minimum${SHARED_FIELDS.section.name}`]: 1,
    [`minimum${SHARED_FIELDS.examForm.name}`]: 1,
    [`minimum${SHARED_FIELDS.formCode.name}`]: 4,
    [`maximum${PAPER_FIELDS.overtimingMinutes.name}`]: 1440,
    [`maximum${PAPER_FIELDS.undertimingMinutes.name}`]: 1440,
    [`maximum${SHARED_FIELDS.interruptionMinutes.name}`]: 1440,
    [`maximum${SHARED_FIELDS.disturbanceMinutes.name}`]: 1440,
  }
}

const CreateIncidentReportForm = ({
  orgId,
  educationPeriodCd,
  notAllowedToSubmit,
  allExamWindows,
  examFormat,
  coordinatorInfo,
  countryCode,
  draft,
  currentValues,
  valid,
  allCourses,
  availableExamWindows,
  fetchingCourses,
  fetchedCourses,
  errorCourses,
  error,
  saveDisabled,
  submitting,
  handleSubmit,
  change,
  initialize,
  fetchAvailableCourses,
}) => {
  const isDomestic = countryCode === 'US'
  const [onlyPaperSelected, setOnlyPaperSelected] = useState(false)
  const allCoursesLength = allCourses?.length ?? 0
  const fetchCourses = useCallback(() => {
    if (orgId && educationPeriodCd && !fetchingCourses && !fetchedCourses && !allCoursesLength) {
      fetchAvailableCourses({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd, allCoursesLength, fetchingCourses, fetchedCourses])
  const isDraft = currentValues.isDraft ?? false
  const isPaper = format => format === PAPER_EXAM
  const fullTestCenterImpactedIndChecked =
    currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]

  const studentHeaderStyles = {
    fontWeight: 'bold',
    opacity: `${currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name] ? '.67' : '1'}`,
  }
  const legendStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    border: 'none',
    marginBottom: '16px',
    lineHeight: '24px',
  }
  const radioBlockStyles = {
    marginBottom: '24px',
  }
  const courseOptions = allCourses.map(({ testCd, courseName }) => ({
    label: courseName,
    value: testCd,
  }))
  const examDateOptions = availableExamWindows.map(
    ({ displayName, examDateTime, adminWindow, noEndOfCourseExam, requiresUnlocking }) => {
      let label = ''
      if (noEndOfCourseExam) {
        label = 'Standard'
      } else if (requiresUnlocking) {
        label = `${displayName} Testing`
      } else {
        label = `${displayName} - ${formatDate(examDateTime, DATETIME_FORMATS.mediumMonthDay)}`
      }
      return {
        label,
        value: adminWindow,
      }
    }
  )
  // fetch available courses
  useEffect(() => {
    fetchCourses()
  }, [fetchCourses])

  useEffect(() => {
    if (
      isPaper &&
      currentValues[PAPER_FIELDS.usedExtraPaperInd.name] &&
      !currentValues[PAPER_FIELDS.defectiveMatsInd.name] &&
      !currentValues[PAPER_FIELDS.multipleChoiceInd.name] &&
      !currentValues[PAPER_FIELDS.freeResponseInd.name] &&
      !currentValues[PAPER_FIELDS.shortAnswerResponseInd.name] &&
      !currentValues[PAPER_FIELDS.orangeBookletInd.name] &&
      !currentValues[PAPER_FIELDS.masterAudioCDsInd.name] &&
      !currentValues[PAPER_FIELDS.equipProbsInd.name] &&
      !currentValues[PAPER_FIELDS.shortAnsrWrngBookInd.name] &&
      !currentValues[PAPER_FIELDS.earlyOpeningSectionInd.name] &&
      !currentValues[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] &&
      !currentValues[PAPER_FIELDS.wrngColorInkInd.name] &&
      !currentValues[PAPER_FIELDS.misplacedAnswersInd.name] &&
      !currentValues[PAPER_FIELDS.missingExamMaterialsInd.name] &&
      !currentValues[PAPER_FIELDS.overtimingMinutesInd.name] &&
      !currentValues[PAPER_FIELDS.undertimingMinutesInd.name] &&
      !currentValues[PAPER_FIELDS.usedInkOnAnswerSheetInd.name] &&
      !currentValues[PAPER_FIELDS.sharedSameAPIDInd.name] &&
      !currentValues[PAPER_FIELDS.testingOffScheduleInd.name] &&
      !currentValues[SHARED_FIELDS.interruptionMinutesInd.name] &&
      !currentValues[SHARED_FIELDS.disturbanceMinutesInd.name] &&
      !currentValues[SHARED_FIELDS.misconductInd.name] &&
      !currentValues[SHARED_FIELDS.studentDismissedInd.name] &&
      !currentValues[SHARED_FIELDS.usedProhibitedDeviceInd.name] &&
      !currentValues[SHARED_FIELDS.removeExamMaterialsInd.name] &&
      !currentValues[SHARED_FIELDS.illnessInd.name] &&
      !currentValues[SHARED_FIELDS.otherInd.name] &&
      !currentValues[SHARED_FIELDS.lateTestingRequiredInd.name] &&
      !currentValues[SHARED_FIELDS.altExamOrderedInd.name]
    ) {
      setOnlyPaperSelected(true)
    } else {
      setOnlyPaperSelected(false)
    }
  }, [isPaper, currentValues])

  useEffect(() => {
    // Execute initialize once after initial render
    initialize(
      initializeIncidentReportData({
        data: draft,
        allExamWindows,
        coordinatorInfo,
      }),
      { keepDirty: false }
    )
  }, [])

  useEffect(() => {
    if (fullTestCenterImpactedIndChecked) {
      // Empty any students listed
      change(SHARED_FIELDS.students.name, [])
      change(SHARED_FIELDS.studentFirstName.name, '')
      change(SHARED_FIELDS.studentLastName.name, '')
      change(SHARED_FIELDS.studentApId.name, '')
    }
  }, [fullTestCenterImpactedIndChecked])

  const onClickAddStudent = () => {
    if (
      !isEmpty(currentValues[SHARED_FIELDS.studentFirstName.name]) &&
      !isEmpty(currentValues[SHARED_FIELDS.studentLastName.name]) &&
      !isEmpty(currentValues[SHARED_FIELDS.studentApId.name])
    ) {
      change(SHARED_FIELDS.students.name, [
        ...currentValues[SHARED_FIELDS.students.name],
        {
          [SHARED_FIELDS.firstName.name]: currentValues[SHARED_FIELDS.studentFirstName.name],
          [SHARED_FIELDS.lastName.name]: currentValues[SHARED_FIELDS.studentLastName.name],
          [SHARED_FIELDS.studentApId.name]: currentValues[SHARED_FIELDS.studentApId.name],
        },
      ])
      change(SHARED_FIELDS.studentFirstName.name, '')
      change(SHARED_FIELDS.studentLastName.name, '')
      change(SHARED_FIELDS.studentApId.name, '')
    }
  }

  const onClickRemoveStudent = e => {
    const apId = e.target.id.split('_')[1]
    const remainingStudents = currentValues?.[SHARED_FIELDS.students.name]?.filter(
      student => student[SHARED_FIELDS.studentApId.name] !== apId
    )
    change(SHARED_FIELDS.students.name, remainingStudents)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <h3>AP Coordinator Contact Information</h3>
            <fieldset>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Text
                    name={SHARED_FIELDS.firstName.name}
                    isRequired={SHARED_FIELDS.firstName.required}
                    label="First Name"
                    validate={[LimitedStringValidation]}
                    maxlength={20}
                  />
                </div>
                <div className="col-sm-6 col-xs-12">
                  <Text
                    name={SHARED_FIELDS.lastName.name}
                    isRequired={SHARED_FIELDS.lastName.required}
                    label="Last Name"
                    validate={[LimitedStringValidation]}
                    maxlength={30}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <PhoneNumber
                    name={SHARED_FIELDS.phone.name}
                    isRequired={SHARED_FIELDS.phone.required}
                    label="Summer Phone Number"
                    placeholder={isDomestic ? 'Enter a 10 digit number' : 'Enter telephone number'}
                    validate={[PhoneValidation]}
                    shouldFormat={true}
                    countryCode={countryCode}
                    normalize={convertToNumberString}
                    maxlength={isDomestic ? DOMESTIC_PHONE_LENGTH : INTERNATIONAL_PHONE_LENGTH}
                  />
                </div>
                <div className="col-sm-6 col-xs-12">
                  <Text
                    name={SHARED_FIELDS.email.name}
                    isRequired={SHARED_FIELDS.email.required}
                    label="Email Address"
                    validate={[EmailValidation]}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <h3>Exam Information</h3>
            <fieldset>
              <div className="row">
                <div className="col-xs-12">
                  <Text
                    name={SHARED_FIELDS.section.name}
                    isRequired={SHARED_FIELDS.section.required}
                    label="Exam Section"
                    placeholder="Enter Section of the Exam"
                    validate={[LimitedStringValidation]}
                    maxlength={20}
                    errorOnPristine={true}
                  />
                  <Select
                    name={SHARED_FIELDS.testCode.name}
                    input={{
                      name: SHARED_FIELDS.testCode.name,
                      onChange: e => {
                        const val = e.target.value
                        initialize(
                          initializeIncidentReportData({
                            data: {
                              coordinator: {
                                [SHARED_FIELDS.firstName.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.firstName.name]
                                  : undefined,
                                [SHARED_FIELDS.lastName.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.lastName.name]
                                  : undefined,
                                [SHARED_FIELDS.phone.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.phone.name]
                                  : undefined,
                                [SHARED_FIELDS.email.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.email.name]
                                  : undefined,
                              },
                              exam: {
                                [SHARED_FIELDS.testCode.name]: val, // this field
                                [SHARED_FIELDS.examDate.name]: null,
                                [SHARED_FIELDS.section.name]: isEmpty(
                                  currentValues[SHARED_FIELDS.testCode.name]
                                )
                                  ? currentValues[SHARED_FIELDS.section.name]
                                  : undefined,
                              },
                            },
                            allExamWindows,
                            coordinatorInfo,
                          }),
                          { keepDirty: false }
                        )
                      },
                      value: currentValues[SHARED_FIELDS.testCode.name],
                    }}
                    isRequired={SHARED_FIELDS.testCode.required}
                    options={courseOptions}
                    label="Exam Title"
                  />
                  <Select
                    name={SHARED_FIELDS.examDate.name}
                    input={{
                      name: SHARED_FIELDS.examDate.name,
                      onChange: e => {
                        const val = e.target.value
                        initialize(
                          initializeIncidentReportData({
                            data: {
                              coordinator: {
                                [SHARED_FIELDS.firstName.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.firstName.name]
                                  : undefined,
                                [SHARED_FIELDS.lastName.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.lastName.name]
                                  : undefined,
                                [SHARED_FIELDS.phone.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.phone.name]
                                  : undefined,
                                [SHARED_FIELDS.email.name]: isEmpty(draft)
                                  ? currentValues[SHARED_FIELDS.email.name]
                                  : undefined,
                              },
                              exam: {
                                [SHARED_FIELDS.examDate.name]: val, // this field
                                [SHARED_FIELDS.testCode.name]:
                                  currentValues[SHARED_FIELDS.testCode.name],
                                [SHARED_FIELDS.section.name]: isEmpty(
                                  currentValues[SHARED_FIELDS.examDate.name]
                                )
                                  ? currentValues[SHARED_FIELDS.section.name]
                                  : undefined,
                              },
                            },
                            allExamWindows,
                            coordinatorInfo,
                          }),
                          { keepDirty: false }
                        )
                      },
                      value: currentValues[SHARED_FIELDS.examDate.name],
                    }}
                    isRequired={SHARED_FIELDS.examDate.required}
                    options={examDateOptions}
                    label="Exam Date"
                    disabled={!examDateOptions.length}
                  />
                </div>
              </div>
            </fieldset>
          </div>
          {(examFormat === PAPER_EXAM &&
            !COURSES_WITH_HIDDEN_EXAM_FORM_SECTION.includes(
              parseInt(currentValues[SHARED_FIELDS.testCode.name], 10)
            )) ||
          examFormat === null ? (
            <div className="col-sm-6 col-xs-12">
              <h3>Exam Form</h3>
              <fieldset>
                <legend style={legendStyles}>
                  The form information appears in the lower right corner of the Section I
                  multiple-choice and Section II free-response exam booklets. Please include the
                  form (e.g., O, A, I) and the form code (e.g., 4RBP).
                </legend>
                <div className="row">
                  <div className="col-xs-12">
                    <Text
                      name={SHARED_FIELDS.examForm.name}
                      isRequired={!isDraft && SHARED_FIELDS.examForm.required}
                      label="Form"
                      validate={!isDraft ? [LetterValidation] : []}
                      maxlength={1}
                      errorOnPristine={!isDraft}
                    />
                    <Text
                      name={SHARED_FIELDS.formCode.name}
                      isRequired={!isDraft && SHARED_FIELDS.formCode.required}
                      label="Form Code"
                      validate={!isDraft ? [FormCodeValidation] : []}
                      maxlength={20}
                      errorOnPristine={!isDraft}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h3 className="cb-required">Type of Incident</h3>
            <fieldset>
              <legend style={legendStyles}>
                Select all that apply, you must select at-least one from the given options.
              </legend>
              {isPaper(examFormat) ? (
                <IncidentReportPaperIncidentFields currentValues={currentValues} change={change} />
              ) : (
                <IncidentReportDigitalIncidentFields
                  currentValues={currentValues}
                  change={change}
                />
              )}
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h3>Additional Information</h3>
            <fieldset>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div style={radioBlockStyles}>
                    <p id="lateTestingRequiredLabel">
                      Did the incident compromise the student&#39;s ability to test enough to
                      require late testing?
                    </p>
                    <Input
                      type="radio"
                      label="Yes"
                      name={SHARED_FIELDS.lateTestingRequiredInd.name}
                      value={true}
                      isRequired={SHARED_FIELDS.lateTestingRequiredInd.required}
                      ariaDescribedBy="lateTestingRequiredLabel"
                      normalize={normalize}
                    />
                    <Input
                      type="radio"
                      label="No"
                      name={SHARED_FIELDS.lateTestingRequiredInd.name}
                      value={false}
                      isRequired={SHARED_FIELDS.lateTestingRequiredInd.required}
                      ariaDescribedBy="lateTestingRequiredLabel"
                      normalize={normalize}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div style={radioBlockStyles}>
                    <p id="altExamOrderedLabel">
                      Has an exam for a subsequent administration been ordered?
                    </p>
                    <Input
                      type="radio"
                      label="Yes"
                      name={SHARED_FIELDS.altExamOrderedInd.name}
                      value={true}
                      isRequired={SHARED_FIELDS.altExamOrderedInd.required}
                      ariaDescribedBy="altExamOrderedLabel"
                      normalize={normalize}
                    />
                    <Input
                      type="radio"
                      label="No"
                      name={SHARED_FIELDS.altExamOrderedInd.name}
                      value={false}
                      isRequired={SHARED_FIELDS.altExamOrderedInd.required}
                      ariaDescribedBy="altExamOrderedLabel"
                      normalize={normalize}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h3 className="cb-required">Students Involved</h3>
            <fieldset>
              <div className="row">
                <div className="col-xs-12">
                  <Input
                    type="checkbox"
                    label="Select if all students taking the exam were impacted."
                    name={SHARED_FIELDS.fullTestCenterImpactedInd.name}
                    isRequired={SHARED_FIELDS.fullTestCenterImpactedInd.required}
                    style={{ marginBottom: '24px' }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <div id="firstNameHeader" style={studentHeaderStyles}>
                        First Name
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-12" style={studentHeaderStyles}>
                      <div id="lastNameHeader">Last Name</div>
                    </div>
                    <div className="col-md-3 col-xs-12" style={studentHeaderStyles}>
                      <div id="apIdHeader">AP ID</div>
                    </div>
                  </div>
                  {currentValues?.[SHARED_FIELDS.students.name]?.map(student => (
                    <div className="row" key={student[SHARED_FIELDS.studentApId.name]}>
                      <div className="col-md-3 col-xs-12">
                        <div aria-describedby="firstNameHeader">
                          {student[SHARED_FIELDS.firstName.name]}
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <div aria-describedby="lastNameHeader">
                          {student[SHARED_FIELDS.lastName.name]}
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <div aria-describedby="apIdHeader">
                          {student[SHARED_FIELDS.studentApId.name]}
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <button
                          type="button"
                          className="btn-link"
                          id={`apId_${student[SHARED_FIELDS.studentApId.name]}`}
                          onClick={onClickRemoveStudent}
                          style={{ color: '#f00' }}
                        >
                          <span className="glyphicon glyphicon-minus" /> Remove Student
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <Text
                        name={SHARED_FIELDS.studentFirstName.name}
                        isRequired={SHARED_FIELDS.studentFirstName.required}
                        placeholder="Enter First Name"
                        ariaLabel="First Name"
                        validate={[LimitedStringValidation]}
                        maxlength={20}
                        disabled={currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]}
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <Text
                        name={SHARED_FIELDS.studentLastName.name}
                        isRequired={SHARED_FIELDS.studentLastName.required}
                        placeholder="Enter Last Name"
                        ariaLabel="Last Name"
                        validate={[LimitedStringValidation]}
                        maxlength={30}
                        disabled={currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]}
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <Text
                        name={SHARED_FIELDS.studentApId.name}
                        isRequired={SHARED_FIELDS.studentApId.required}
                        placeholder="Enter AP ID"
                        ariaLabel="AP ID"
                        normalize={val => val.toUpperCase()}
                        validate={[ApIdValidation]}
                        maxlength={8}
                        disabled={currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]}
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        disabled={
                          isEmpty(currentValues[SHARED_FIELDS.studentFirstName.name]) ||
                          isEmpty(currentValues[SHARED_FIELDS.studentLastName.name]) ||
                          isEmpty(currentValues[SHARED_FIELDS.studentApId.name]) ||
                          ApIdValidation(currentValues[SHARED_FIELDS.studentApId.name])
                        }
                        onClick={onClickAddStudent}
                        style={{ marginTop: 0 }}
                      >
                        <span className="glyphicon glyphicon-plus" /> Add Another Student
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h3>Incident Detail</h3>
            <fieldset>
              <div className="row">
                <div className="col-xs-12">
                  <Textarea
                    name={SHARED_FIELDS.incidentDetail.name}
                    label="Explanation (Describe all events and actions taken)"
                    rows="5"
                    isRequired={!onlyPaperSelected && !isDraft}
                    maxlength={20000}
                    placeholder="Enter explanation..."
                    formGroupStyle={{ marginBottom: 0 }}
                    errorOnPristine={!isDraft}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        {error || errorCourses ? (
          <div className="row">
            <div className="col-xs-12">
              {error ? (
                <p
                  className="cb-error-msg"
                  role="alert"
                  style={{ marginTop: '24px' }}
                  aria-live="polite"
                >
                  {error}
                </p>
              ) : null}
              {errorCourses ? <Error message={errorCourses} style={{ marginTop: '24px' }} /> : null}
            </div>
          </div>
        ) : null}
        {valid && notAllowedToSubmit ? (
          <p className="cb-error-msg" role="alert" style={{ marginTop: '24px' }} aria-live="polite">
            {NOT_ALLOWED_TO_SUBMIT}
          </p>
        ) : null}
        <div className="row">
          <div className="col-xs-12">
            <IncidentReportSubmitButton
              disabled={!valid || notAllowedToSubmit || submitting}
              addStudent={onClickAddStudent}
            />
            <IncidentReportSaveDraftButton
              disabled={saveDisabled || notAllowedToSubmit}
              submitting={submitting}
              isDraft={isDraft}
              setDraft={change}
              addStudent={onClickAddStudent}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default connect(mapStateToProps, {
  change,
  initialize,
  fetchAvailableCourses,
  submitIncidentReport,
  openModal,
})(
  reduxForm({
    form: INCIDENT_REPORT_FORM_NAME,
    enableReinitialize: true,
    updateUnregisteredFields: true,
    keepDirtyOnReinitialize: true,
    validate: (vals, { examFormat, formFieldsMeta }) => {
      const isDraft = vals.isDraft ?? false
      const isPaper = format => format === PAPER_EXAM
      const errors = {}

      if (
        !isDraft &&
        isPaper(examFormat) &&
        vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.multipleChoiceInd.name] &&
        !vals[PAPER_FIELDS.freeResponseInd.name] &&
        !vals[PAPER_FIELDS.shortAnswerResponseInd.name] &&
        !vals[PAPER_FIELDS.orangeBookletInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDs.name]
      ) {
        errors[PAPER_FIELDS.defectiveMatsInd.name] =
          'Error: At least one of the following options must be selected'
      }

      if (
        !isDraft &&
        isPaper(examFormat) &&
        (formFieldsMeta[PAPER_FIELDS.defectiveMatsInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.equipProbsInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.shortAnsrWrngBookInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.earlyOpeningSectionInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.mixUpFreeResponseBooksInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.wrngColorInkInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.misplacedAnswersInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.illnessInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.missingExamMaterialsInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.misconductInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.overtimingMinutesInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.undertimingMinutesInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.interruptionMinutesInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.disturbanceMinutesInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.usedInkOnAnswerSheetInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.usedExtraPaperInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.sharedSameAPIDInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.testingOffScheduleInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.otherInd.name]?.visited) &&
        !vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.equipProbsInd.name] &&
        !vals[PAPER_FIELDS.shortAnsrWrngBookInd.name] &&
        !vals[PAPER_FIELDS.earlyOpeningSectionInd.name] &&
        !vals[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] &&
        !vals[PAPER_FIELDS.wrngColorInkInd.name] &&
        !vals[PAPER_FIELDS.misplacedAnswersInd.name] &&
        !vals[SHARED_FIELDS.illnessInd.name] &&
        !vals[PAPER_FIELDS.missingExamMaterialsInd.name] &&
        !vals[SHARED_FIELDS.misconductInd.name] &&
        !vals[PAPER_FIELDS.overtimingMinutesInd.name] &&
        !vals[PAPER_FIELDS.undertimingMinutesInd.name] &&
        !vals[SHARED_FIELDS.interruptionMinutesInd.name] &&
        !vals[SHARED_FIELDS.disturbanceMinutesInd.name] &&
        !vals[PAPER_FIELDS.usedInkOnAnswerSheetInd.name] &&
        !vals[PAPER_FIELDS.usedExtraPaperInd.name] &&
        !vals[PAPER_FIELDS.sharedSameAPIDInd.name] &&
        !vals[PAPER_FIELDS.testingOffScheduleInd.name] &&
        !vals[SHARED_FIELDS.otherInd.name]
      ) {
        errors._error = 'Error: At least one type of incident must be selected'
      }

      if (
        !isDraft &&
        !isPaper(examFormat) &&
        (formFieldsMeta[DIGITAL_FIELDS.batteryDiedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.connectionDroppedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.loanerDeviceIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.anotherTestAppInstalledInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.blueBookNotInstalledInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.examSetUpNotCompletedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.noAdmissionTicketInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.studentTechIssueOther.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotOpenInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotSignInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.testNotAvailableInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.inAccurateTimingInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.contentDisplayIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.answerNotSubmittedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.blueBookProblemOther.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotAccessTDTKInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.studentCheckInIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.inAccurateStudentStatusInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.testDayToolKitProblemOther.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.networkOutageInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.powerOutageInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.facilityTechIssueOther.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.misconductInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.staffTechIssue.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.illnessInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.disturbanceMinutesInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.interruptionMinutesInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.otherInd.name]?.visited) &&
        !vals[DIGITAL_FIELDS.batteryDiedInd.name] &&
        !vals[DIGITAL_FIELDS.connectionDroppedInd.name] &&
        !vals[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name] &&
        !vals[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name] &&
        !vals[DIGITAL_FIELDS.loanerDeviceIssueInd.name] &&
        !vals[DIGITAL_FIELDS.anotherTestAppInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookNotInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.examSetUpNotCompletedInd.name] &&
        !vals[DIGITAL_FIELDS.noAdmissionTicketInd.name] &&
        !vals[DIGITAL_FIELDS.studentTechIssueOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotOpenInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotSignInd.name] &&
        !vals[DIGITAL_FIELDS.testNotAvailableInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateTimingInd.name] &&
        !vals[DIGITAL_FIELDS.contentDisplayIssueInd.name] &&
        !vals[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name] &&
        !vals[DIGITAL_FIELDS.answerNotSubmittedInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookProblemOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotAccessTDTKInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name] &&
        !vals[DIGITAL_FIELDS.studentCheckInIssueInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentStatusInd.name] &&
        !vals[DIGITAL_FIELDS.testDayToolKitProblemOther.name] &&
        !vals[DIGITAL_FIELDS.networkOutageInd.name] &&
        !vals[DIGITAL_FIELDS.powerOutageInd.name] &&
        !vals[DIGITAL_FIELDS.facilityTechIssueOther.name] &&
        !vals[SHARED_FIELDS.misconductInd.name] &&
        !vals[DIGITAL_FIELDS.staffTechIssue.name] &&
        !vals[SHARED_FIELDS.illnessInd.name] &&
        !vals[SHARED_FIELDS.disturbanceMinutesInd.name] &&
        !vals[SHARED_FIELDS.interruptionMinutesInd.name] &&
        !vals[SHARED_FIELDS.otherInd.name]
      ) {
        errors._error = 'Error: At least one type of incident must be selected'
      }

      if (
        !isDraft &&
        (formFieldsMeta[SHARED_FIELDS.fullTestCenterImpactedInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.studentFirstName.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.studentLastName.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.studentApId.name]?.visited) &&
        vals[SHARED_FIELDS.students.name]?.length === 0 &&
        (isEmpty(vals[SHARED_FIELDS.studentFirstName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentLastName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentApId.name])) &&
        !vals[SHARED_FIELDS.fullTestCenterImpactedInd.name]
      ) {
        errors._error =
          'Error: Must either select that all students were impacted or at least one student involved must be listed.'
      }

      return errors
    },
    onSubmit: async (
      vals,
      dispatch,
      { orgId, educationPeriodCd, examFormat, submitIncidentReport, openModal }
    ) => {
      const isDraft = vals.isDraft ?? false
      const isPaper = format => format === PAPER_EXAM

      if (
        isPaper(examFormat) &&
        vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.multipleChoiceInd.name] &&
        !vals[PAPER_FIELDS.freeResponseInd.name] &&
        !vals[PAPER_FIELDS.shortAnswerResponseInd.name] &&
        !vals[PAPER_FIELDS.orangeBookletInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDs.name]
      ) {
        throw new SubmissionError({
          [PAPER_FIELDS.defectiveMatsInd.name]:
            'Error: At least one of the following options must be selected',
        })
      }

      if (
        !isDraft &&
        isPaper(examFormat) &&
        !vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.equipProbsInd.name] &&
        !vals[PAPER_FIELDS.shortAnsrWrngBookInd.name] &&
        !vals[PAPER_FIELDS.earlyOpeningSectionInd.name] &&
        !vals[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] &&
        !vals[PAPER_FIELDS.wrngColorInkInd.name] &&
        !vals[PAPER_FIELDS.misplacedAnswersInd.name] &&
        !vals[SHARED_FIELDS.illnessInd.name] &&
        !vals[PAPER_FIELDS.missingExamMaterialsInd.name] &&
        !vals[SHARED_FIELDS.misconductInd.name] &&
        !vals[PAPER_FIELDS.overtimingMinutesInd.name] &&
        !vals[PAPER_FIELDS.undertimingMinutesInd.name] &&
        !vals[SHARED_FIELDS.interruptionMinutesInd.name] &&
        !vals[SHARED_FIELDS.disturbanceMinutesInd.name] &&
        !vals[PAPER_FIELDS.usedInkOnAnswerSheetInd.name] &&
        !vals[PAPER_FIELDS.usedExtraPaperInd.name] &&
        !vals[PAPER_FIELDS.sharedSameAPIDInd.name] &&
        !vals[PAPER_FIELDS.testingOffScheduleInd.name] &&
        !vals[SHARED_FIELDS.otherInd.name]
      ) {
        throw new SubmissionError({
          _error: 'Error: At least one type of incident must be selected',
        })
      }

      if (
        !isDraft &&
        !isPaper(examFormat) &&
        !vals[DIGITAL_FIELDS.batteryDiedInd.name] &&
        !vals[DIGITAL_FIELDS.connectionDroppedInd.name] &&
        !vals[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name] &&
        !vals[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name] &&
        !vals[DIGITAL_FIELDS.loanerDeviceIssueInd.name] &&
        !vals[DIGITAL_FIELDS.anotherTestAppInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookNotInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.examSetUpNotCompletedInd.name] &&
        !vals[DIGITAL_FIELDS.noAdmissionTicketInd.name] &&
        !vals[DIGITAL_FIELDS.studentTechIssueOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotOpenInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotSignInd.name] &&
        !vals[DIGITAL_FIELDS.testNotAvailableInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateTimingInd.name] &&
        !vals[DIGITAL_FIELDS.contentDisplayIssueInd.name] &&
        !vals[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name] &&
        !vals[DIGITAL_FIELDS.answerNotSubmittedInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookProblemOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotAccessTDTKInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name] &&
        !vals[DIGITAL_FIELDS.studentCheckInIssueInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentStatusInd.name] &&
        !vals[DIGITAL_FIELDS.testDayToolKitProblemOther.name] &&
        !vals[DIGITAL_FIELDS.networkOutageInd.name] &&
        !vals[DIGITAL_FIELDS.powerOutageInd.name] &&
        !vals[DIGITAL_FIELDS.facilityTechIssueOther.name] &&
        !vals[SHARED_FIELDS.misconductInd.name] &&
        !vals[DIGITAL_FIELDS.staffTechIssue.name] &&
        !vals[SHARED_FIELDS.illnessInd.name] &&
        !vals[SHARED_FIELDS.interruptionMinutesInd.name] &&
        !vals[SHARED_FIELDS.disturbanceMinutesInd.name] &&
        !vals[SHARED_FIELDS.otherInd.name]
      ) {
        throw new SubmissionError({
          _error: 'Error: At least one type of incident must be selected',
        })
      }

      if (
        !isDraft &&
        vals[SHARED_FIELDS.students.name]?.length === 0 &&
        (isEmpty(vals[SHARED_FIELDS.studentFirstName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentLastName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentApId.name])) &&
        !vals[SHARED_FIELDS.fullTestCenterImpactedInd.name]
      ) {
        throw new SubmissionError({
          _error:
            'Error: Must either select that all students were impacted or at least one student involved must be listed.',
        })
      }
      const submitValues = {
        coordinator: {
          [SHARED_FIELDS.firstName.name]: vals[SHARED_FIELDS.firstName.name],
          [SHARED_FIELDS.lastName.name]: vals[SHARED_FIELDS.lastName.name],
          [SHARED_FIELDS.phone.name]: vals[SHARED_FIELDS.phone.name],
          [SHARED_FIELDS.email.name]: vals[SHARED_FIELDS.email.name],
        },
        exam: {
          [SHARED_FIELDS.testCode.name]: parseInt(vals[SHARED_FIELDS.testCode.name], 10),
          [SHARED_FIELDS.section.name]: vals[SHARED_FIELDS.section.name],
          [SHARED_FIELDS.examDate.name]: vals[SHARED_FIELDS.examDate.name],
          ...((examFormat === PAPER_EXAM &&
            !COURSES_WITH_HIDDEN_EXAM_FORM_SECTION.includes(
              parseInt(vals[SHARED_FIELDS.testCode.name], 10)
            )) ||
          examFormat === null
            ? {
                [SHARED_FIELDS.examForm.name]: !isEmpty(vals[SHARED_FIELDS.examForm.name])
                  ? vals[SHARED_FIELDS.examForm.name]
                  : '',
                [SHARED_FIELDS.formCode.name]: !isEmpty(vals[SHARED_FIELDS.formCode.name])
                  ? vals[SHARED_FIELDS.formCode.name]
                  : '',
              }
            : {}),
          examFormat,
        },
        incidentType: {
          [SHARED_FIELDS.interruptionMinutes.name]: vals[SHARED_FIELDS.interruptionMinutesInd.name]
            ? parseInt(vals[SHARED_FIELDS.interruptionMinutes.name], 10)
            : null,
          [SHARED_FIELDS.disturbanceMinutes.name]: vals[SHARED_FIELDS.disturbanceMinutesInd.name]
            ? parseInt(vals[SHARED_FIELDS.disturbanceMinutes.name], 10)
            : null,
          [SHARED_FIELDS.illnessInd.name]: vals[SHARED_FIELDS.illnessInd.name],
          [SHARED_FIELDS.otherInd.name]: vals[SHARED_FIELDS.otherInd.name],
          ...(isPaper(examFormat)
            ? {
                defectiveMaterials: {
                  [PAPER_FIELDS.multipleChoiceInd.name]: vals[PAPER_FIELDS.multipleChoiceInd.name],
                  [PAPER_FIELDS.freeResponseInd.name]: vals[PAPER_FIELDS.freeResponseInd.name],
                  [PAPER_FIELDS.shortAnswerResponseInd.name]:
                    vals[PAPER_FIELDS.shortAnswerResponseInd.name],
                  [PAPER_FIELDS.orangeBookletInd.name]: vals[PAPER_FIELDS.orangeBookletInd.name],
                  [PAPER_FIELDS.masterAudioCDs.name]: vals[PAPER_FIELDS.masterAudioCDsInd.name]
                    ? vals[PAPER_FIELDS.masterAudioCDs.name]
                    : null,
                },
                [PAPER_FIELDS.equipProbsInd.name]: vals[PAPER_FIELDS.equipProbsInd.name],
                [PAPER_FIELDS.shortAnsrWrngBookInd.name]:
                  vals[PAPER_FIELDS.shortAnsrWrngBookInd.name],
                [PAPER_FIELDS.earlyOpeningSectionInd.name]:
                  vals[PAPER_FIELDS.earlyOpeningSectionInd.name],
                [PAPER_FIELDS.mixUpFreeResponseBooksInd.name]:
                  vals[PAPER_FIELDS.mixUpFreeResponseBooksInd.name],
                [PAPER_FIELDS.wrngColorInkInd.name]: vals[PAPER_FIELDS.wrngColorInkInd.name],
                [PAPER_FIELDS.misplacedAnswersInd.name]:
                  vals[PAPER_FIELDS.misplacedAnswersInd.name],
                [PAPER_FIELDS.missingExamMaterialsInd.name]:
                  vals[PAPER_FIELDS.missingExamMaterialsInd.name],
                misconduct: {
                  [SHARED_FIELDS.misconductInd.name]: vals[SHARED_FIELDS.misconductInd.name],
                  [SHARED_FIELDS.studentDismissedInd.name]:
                    vals[SHARED_FIELDS.studentDismissedInd.name],
                  type: {
                    [SHARED_FIELDS.usedProhibitedDeviceInd.name]:
                      vals[SHARED_FIELDS.usedProhibitedDeviceInd.name],
                    [SHARED_FIELDS.removeExamMaterialsInd.name]:
                      vals[SHARED_FIELDS.removeExamMaterialsInd.name],
                  },
                },
                [PAPER_FIELDS.overtimingMinutes.name]: vals[PAPER_FIELDS.overtimingMinutesInd.name]
                  ? parseInt(vals[PAPER_FIELDS.overtimingMinutes.name], 10)
                  : null,
                [PAPER_FIELDS.undertimingMinutes.name]: vals[
                  PAPER_FIELDS.undertimingMinutesInd.name
                ]
                  ? parseInt(vals[PAPER_FIELDS.undertimingMinutes.name], 10)
                  : null,
                [PAPER_FIELDS.usedInkOnAnswerSheetInd.name]:
                  vals[PAPER_FIELDS.usedInkOnAnswerSheetInd.name],
                [PAPER_FIELDS.usedExtraPaperInd.name]: vals[PAPER_FIELDS.usedExtraPaperInd.name],
                [PAPER_FIELDS.sharedSameAPIDInd.name]: vals[PAPER_FIELDS.sharedSameAPIDInd.name],
                [PAPER_FIELDS.testingOffScheduleInd.name]:
                  vals[PAPER_FIELDS.testingOffScheduleInd.name],
              }
            : {
                studentTechIssue: {
                  [DIGITAL_FIELDS.batteryDiedInd.name]: vals[DIGITAL_FIELDS.batteryDiedInd.name],
                  [DIGITAL_FIELDS.connectionDroppedInd.name]:
                    vals[DIGITAL_FIELDS.connectionDroppedInd.name],
                  [DIGITAL_FIELDS.deviceFrozeOrCrashInd.name]:
                    vals[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name],
                  [DIGITAL_FIELDS.deviceDidNotMeetReqInd.name]:
                    vals[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name],
                  [DIGITAL_FIELDS.loanerDeviceIssueInd.name]:
                    vals[DIGITAL_FIELDS.loanerDeviceIssueInd.name],
                  [DIGITAL_FIELDS.anotherTestAppInstalledInd.name]:
                    vals[DIGITAL_FIELDS.anotherTestAppInstalledInd.name],
                  [DIGITAL_FIELDS.blueBookNotInstalledInd.name]:
                    vals[DIGITAL_FIELDS.blueBookNotInstalledInd.name],
                  [DIGITAL_FIELDS.examSetUpNotCompletedInd.name]:
                    vals[DIGITAL_FIELDS.examSetUpNotCompletedInd.name],
                  [DIGITAL_FIELDS.noAdmissionTicketInd.name]:
                    vals[DIGITAL_FIELDS.noAdmissionTicketInd.name],
                  otherInd: vals[DIGITAL_FIELDS.studentTechIssueOther.name],
                },
                blueBookProblem: {
                  [DIGITAL_FIELDS.couldNotOpenInd.name]: vals[DIGITAL_FIELDS.couldNotOpenInd.name],
                  [DIGITAL_FIELDS.couldNotSignInd.name]: vals[DIGITAL_FIELDS.couldNotSignInd.name],
                  [DIGITAL_FIELDS.testNotAvailableInd.name]:
                    vals[DIGITAL_FIELDS.testNotAvailableInd.name],
                  [DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name]:
                    vals[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name],
                  [DIGITAL_FIELDS.inAccurateTimingInd.name]:
                    vals[DIGITAL_FIELDS.inAccurateTimingInd.name],
                  [DIGITAL_FIELDS.contentDisplayIssueInd.name]:
                    vals[DIGITAL_FIELDS.contentDisplayIssueInd.name],
                  [DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name]:
                    vals[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name],
                  [DIGITAL_FIELDS.answerNotSubmittedInd.name]:
                    vals[DIGITAL_FIELDS.answerNotSubmittedInd.name],
                  otherInd: vals[DIGITAL_FIELDS.blueBookProblemOther.name],
                },
                [DIGITAL_FIELDS.staffTechIssue.name]: vals[DIGITAL_FIELDS.staffTechIssue.name],
                facilityTechIssue: {
                  [DIGITAL_FIELDS.networkOutageInd.name]:
                    vals[DIGITAL_FIELDS.networkOutageInd.name],
                  [DIGITAL_FIELDS.powerOutageInd.name]: vals[DIGITAL_FIELDS.powerOutageInd.name],
                  otherInd: vals[DIGITAL_FIELDS.facilityTechIssueOther.name],
                },
                testDayToolKitProblem: {
                  [DIGITAL_FIELDS.couldNotAccessTDTKInd.name]:
                    vals[DIGITAL_FIELDS.couldNotAccessTDTKInd.name],
                  [DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name]:
                    vals[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name],
                  [DIGITAL_FIELDS.studentCheckInIssueInd.name]:
                    vals[DIGITAL_FIELDS.studentCheckInIssueInd.name],
                  [DIGITAL_FIELDS.inAccurateStudentStatusInd.name]:
                    vals[DIGITAL_FIELDS.inAccurateStudentStatusInd.name],
                  otherInd: vals[DIGITAL_FIELDS.testDayToolKitProblemOther.name],
                },
                misconduct: {
                  [SHARED_FIELDS.misconductInd.name]: vals[SHARED_FIELDS.misconductInd.name],
                  [SHARED_FIELDS.studentDismissedInd.name]:
                    vals[SHARED_FIELDS.studentDismissedInd.name],
                  type: {
                    [SHARED_FIELDS.usedProhibitedDeviceInd.name]:
                      vals[SHARED_FIELDS.usedProhibitedDeviceInd.name],
                    [SHARED_FIELDS.removeExamMaterialsInd.name]:
                      vals[SHARED_FIELDS.removeExamMaterialsInd.name],
                  },
                },
              }),
        },
        [SHARED_FIELDS.lateTestingRequiredInd.name]:
          vals[SHARED_FIELDS.lateTestingRequiredInd.name],
        [SHARED_FIELDS.altExamOrderedInd.name]: vals[SHARED_FIELDS.altExamOrderedInd.name],
        [SHARED_FIELDS.fullTestCenterImpactedInd.name]:
          vals[SHARED_FIELDS.fullTestCenterImpactedInd.name],
        [SHARED_FIELDS.students.name]: vals[SHARED_FIELDS.students.name],
        [SHARED_FIELDS.incidentDetail.name]: vals[SHARED_FIELDS.incidentDetail.name],
      }
      await submitIncidentReport({ orgId, educationPeriodCd, values: { ...submitValues, isDraft } })
      if (isDraft) {
        openModal('SavedIncidentReportDraftModal')
      }
    },
    onSubmitFail: (errors, dispatch, submitError, { change }) => {
      // console.error('*** submitError:', submitError)
      change('isDraft', false)
      // throw new SubmissionError({
      //   _error: 'An error occurred while submitting this Incident Report.',
      // })
    },
  })(CreateIncidentReportForm)
)
