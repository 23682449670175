import axios, { getServerErrorMessage } from '../utils/axios'
import { sortColumnByDate } from '../utils/sort'
import { getExamFormats } from '../selectors/examWindows'
import {
  FETCH_PACKING_LIST_SUMMARY_PENDING,
  FETCH_PACKING_LIST_SUMMARY_FULFILLED,
  FETCH_PACKING_LIST_SUMMARY_REJECTED,
  FETCH_PACKING_LIST_DETAILS_PENDING,
  FETCH_PACKING_LIST_DETAILS_FULFILLED,
  FETCH_PACKING_LIST_DETAILS_REJECTED,
  UPDATE_PACKING_CARTONS_PENDING,
  UPDATE_PACKING_CARTONS_FULFILLED,
  UPDATE_PACKING_CARTONS_REJECTED,
  UPDATE_PACKING_LIST_ITEM_PENDING,
  UPDATE_PACKING_LIST_ITEM_FULFILLED,
  UPDATE_PACKING_LIST_ITEM_REJECTED,
  UPDATE_PACKING_LIST_STATUS_COUNTS,
  RESET_UPDATE_PACKING_LIST_ITEM,
  FLUSH_PACKING_LIST_DETAILS,
  STATUS_CONFIRMED,
  SUBMIT_PACKING_LIST_PENDING,
  SUBMIT_PACKING_LIST_FULFILLED,
  SUBMIT_PACKING_LIST_REJECTED,
  PACKAGES_ENUM,
  STANDARD,
  PACKING_LIST_GROUPS,
  RESPONSE_BOOKS_RETURNED,
  FREE_RESPONSE_BOOKLETS_RETURNED,
} from '../constants/PackingListConstants'

function normalizePackingListSummary({ packingLists, splitShipment }) {
  const groupings = {}
  PACKING_LIST_GROUPS.forEach(groupName => {
    // Collect packages in the same admin window
    const group = packingLists.filter(pkg => pkg.testDayType === groupName)
    const canSplit = groupName === STANDARD
    if (group?.length > 1) {
      // Sort packages in the order of the examTypes in PACKAGES_ENUM array
      const sortedPackages = group
        .slice()
        .sort((a, b) => PACKAGES_ENUM.indexOf(a.examType) - PACKAGES_ENUM.indexOf(b.examType))
      // Sort elements by due date
      const sortedWithWeeks = sortColumnByDate(sortedPackages, ['shipmentDueDate'], ['asc']).map(
        (pkg, i) => {
          // Only assign weekNumber if window is the split shipment window
          // AND splitShipment is true
          if (canSplit && splitShipment) {
            if (i === 0) {
              return { ...pkg, weekNumber: 1 } // First element is week 1
            }
            return { ...pkg, weekNumber: 2 } // All other elements are week 2
          }
          return pkg
        }
      )
      groupings[groupName] = sortedWithWeeks
    } else {
      groupings[groupName] = group
    }
  })
  return groupings
}

export const fetchPackingListSummary =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    dispatch({ type: FETCH_PACKING_LIST_SUMMARY_PENDING })
    try {
      const { data } = await axios.get(
        `${Config.API_URL}/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/packing-shipments`
      )
      const normalizedPackingList = data.packingLists.length
        ? normalizePackingListSummary({ ...data })
        : {}
      dispatch({
        type: FETCH_PACKING_LIST_SUMMARY_FULFILLED,
        payload: {
          data: { ...data, packingLists: normalizedPackingList },
        },
      })
    } catch (err) {
      dispatch({ type: FETCH_PACKING_LIST_SUMMARY_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const fetchPackingListDetails =
  ({ orgId, educationPeriodCd, packingShipmentsId, packingListId }) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_PACKING_LIST_DETAILS_PENDING })
    try {
      const { data } = await axios.get(
        `${Config.API_URL}/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/packing-shipments/${packingShipmentsId}/packing-list/${packingListId}`
      )
      const { packingListItemMappingDTOS: packingListItems } = data
      delete data.packingListItemMappingDTOS
      dispatch({
        type: FETCH_PACKING_LIST_DETAILS_FULFILLED,
        payload: { ...data, packingListItems },
      })
    } catch (err) {
      dispatch({ type: FETCH_PACKING_LIST_DETAILS_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const updateCartons =
  ({ orgId, educationPeriodCd, sendPackage, packingShipmentsId, packingListId }) =>
  async dispatch => {
    dispatch({ type: UPDATE_PACKING_CARTONS_PENDING })
    try {
      const { data } = await axios.put(
        `${Config.API_URL}/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/packing-shipments/${packingShipmentsId}/packing-list/${packingListId}`,
        sendPackage
      )
      dispatch({
        type: UPDATE_PACKING_CARTONS_FULFILLED,
        payload: { data: { ...sendPackage, ...data } },
      })
    } catch (err) {
      dispatch({ type: UPDATE_PACKING_CARTONS_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const submitPackingList =
  ({ orgId, educationPeriodCd, packingShipmentsId, packingListId, payload }) =>
  async dispatch => {
    dispatch({ type: SUBMIT_PACKING_LIST_PENDING })
    try {
      const payloadSent = { packingListId, ...payload }
      const { data } = await axios.put(
        `${Config.API_URL}/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/packing-shipments/${packingShipmentsId}/packing-list/${packingListId}`,
        payloadSent
      )
      dispatch({ type: SUBMIT_PACKING_LIST_FULFILLED, payload: { data: { ...data } } })
    } catch (err) {
      dispatch({ type: SUBMIT_PACKING_LIST_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const updatePackingListItems =
  ({ orgId, educationPeriodCd, packingListDetails, packingListItemId, payload }) =>
  async (dispatch, getState) => {
    dispatch({ type: UPDATE_PACKING_LIST_ITEM_PENDING, payload: packingListItemId })
    try {
      const { packingListItems, testDayType, packingShipmentsId, packingListId } =
        packingListDetails
      const payloadSent = { packingListId, packingListItemId, ...payload }
      const { data } = await axios.put(
        `${Config.API_URL}/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/packing-shipments/${packingShipmentsId}/packing-list/${packingListId}/items/${packingListItemId}`,
        payloadSent
      )
      dispatch({ type: UPDATE_PACKING_LIST_ITEM_FULFILLED, payload: { ...data } })
      let confirmedCourses = 0
      packingListItems.forEach(item => {
        if (item.status === STATUS_CONFIRMED) {
          confirmedCourses += 1
        }
      })
      dispatch({
        type: UPDATE_PACKING_LIST_STATUS_COUNTS,
        payload: { testDayType, packingListId, confirmedCourses },
      })
    } catch (err) {
      const payload = { error: getServerErrorMessage(err), packingListItemId }
      dispatch({ type: UPDATE_PACKING_LIST_ITEM_REJECTED, payload })
    }
  }

export const resetUpdatePackingListItems = () => dispatch =>
  dispatch({ type: RESET_UPDATE_PACKING_LIST_ITEM })

export const flushPackingListDetails = () => dispatch =>
  dispatch({ type: FLUSH_PACKING_LIST_DETAILS })
