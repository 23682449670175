import {
  Input,
  InlineText,
  RequiredValidation,
  PositiveNumberValidation,
  PositiveMaximumNumberValidation,
} from '../common'
import {
  INCIDENT_REPORT_PAPER_FIELD_NAMES as PAPER_FIELDS,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
} from '../../constants/IncidentReportConstants'

const IncidentReportPaperIncidentFields = ({ currentValues, change }) => {
  const childControlStyles = {
    marginLeft: '26px',
  }
  return (
    <div className="row">
      <div className="col-sm-6 col-xs-12">
        <div style={{ marginBottom: '24px' }}>
          <Input
            type="checkbox"
            label="Defective Materials (Select all that apply)"
            input={{
              name: PAPER_FIELDS.defectiveMatsInd.name,
              checked: currentValues[PAPER_FIELDS.defectiveMatsInd.name],
              onChange: e => {
                const { checked } = e.target
                change(PAPER_FIELDS.defectiveMatsInd.name, checked)
                if (!checked) {
                  change(PAPER_FIELDS.multipleChoiceInd.name, false)
                  change(PAPER_FIELDS.freeResponseInd.name, false)
                  change(PAPER_FIELDS.shortAnswerResponseInd.name, false)
                  change(PAPER_FIELDS.orangeBookletInd.name, false)
                  change(PAPER_FIELDS.masterAudioCDsInd.name, false)
                  change(PAPER_FIELDS.masterAudioCDs.name, null)
                }
              },
            }}
            name={PAPER_FIELDS.defectiveMatsInd.name}
            isRequired={PAPER_FIELDS.defectiveMatsInd.required}
            showErrorsOnLoad={true}
          />
          <Input
            type="checkbox"
            label="Multiple Choice"
            name={PAPER_FIELDS.multipleChoiceInd.name}
            isRequired={PAPER_FIELDS.multipleChoiceInd.required}
            disabled={!currentValues[PAPER_FIELDS.defectiveMatsInd.name]}
            style={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Free-Response"
            name={PAPER_FIELDS.freeResponseInd.name}
            isRequired={PAPER_FIELDS.freeResponseInd.required}
            disabled={!currentValues[PAPER_FIELDS.defectiveMatsInd.name]}
            style={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Short-Answer Response"
            name={PAPER_FIELDS.shortAnswerResponseInd.name}
            isRequired={PAPER_FIELDS.shortAnswerResponseInd.required}
            disabled={!currentValues[PAPER_FIELDS.defectiveMatsInd.name]}
            style={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Orange Booklet"
            name={PAPER_FIELDS.orangeBookletInd.name}
            isRequired={PAPER_FIELDS.orangeBookletInd.required}
            disabled={!currentValues[PAPER_FIELDS.defectiveMatsInd.name]}
            style={childControlStyles}
          />
          <InlineText
            name={PAPER_FIELDS.masterAudioCDs.name}
            validate={
              currentValues[PAPER_FIELDS.masterAudioCDsInd.name] ? [RequiredValidation] : null
            }
            label=""
            placeholder="Enter description"
            controlInput={{
              type: 'checkbox',
              name: PAPER_FIELDS.masterAudioCDsInd.name,
              label: 'Master Audio CDs',
              disabled: !currentValues[PAPER_FIELDS.defectiveMatsInd.name],
              input: {
                onChange: e => {
                  const { checked } = e.target
                  change(PAPER_FIELDS.masterAudioCDsInd.name, checked)
                  if (!checked) {
                    change(PAPER_FIELDS.masterAudioCDs.name, null)
                  } else {
                    setTimeout(
                      () => document.getElementById(PAPER_FIELDS.masterAudioCDs.name).focus(),
                      50
                    )
                  }
                },
              },
            }}
            disabled={
              !currentValues[PAPER_FIELDS.defectiveMatsInd.name] ||
              !currentValues[PAPER_FIELDS.masterAudioCDsInd.name]
            }
            isRequired={currentValues[PAPER_FIELDS.masterAudioCDsInd.name]}
            withFlexbox={false}
            fullWidthError={true}
            formGroupStyles={childControlStyles}
            textboxGroupStyles={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Equipment Problems"
            name={PAPER_FIELDS.equipProbsInd.name}
            isRequired={PAPER_FIELDS.equipProbsInd.required}
          />
          <Input
            type="checkbox"
            label="Short-answer responses and/or free-responses written in wrong book"
            name={PAPER_FIELDS.shortAnsrWrngBookInd.name}
            isRequired={PAPER_FIELDS.shortAnsrWrngBookInd.required}
          />
          <Input
            type="checkbox"
            label="Early opening of Section II packs"
            name={PAPER_FIELDS.earlyOpeningSectionInd.name}
            isRequired={PAPER_FIELDS.earlyOpeningSectionInd.required}
          />
          <Input
            type="checkbox"
            label="Mix-up or redistribution of free-response books during administration/administration irregularity"
            name={PAPER_FIELDS.mixUpFreeResponseBooksInd.name}
            isRequired={PAPER_FIELDS.mixUpFreeResponseBooksInd.required}
          />
          <Input
            type="checkbox"
            label="Student used inappropriate ink color in the free-response booklet"
            name={PAPER_FIELDS.wrngColorInkInd.name}
            isRequired={PAPER_FIELDS.wrngColorInkInd.required}
          />
          <Input
            type="checkbox"
            label="Misplaced Answers"
            name={PAPER_FIELDS.misplacedAnswersInd.name}
            isRequired={PAPER_FIELDS.misplacedAnswersInd.required}
          />
          <Input
            type="checkbox"
            label="Illness"
            name={SHARED_FIELDS.illnessInd.name}
            isRequired={SHARED_FIELDS.illnessInd.required}
          />
          <Input
            type="checkbox"
            label="Missing Exam Materials"
            name={PAPER_FIELDS.missingExamMaterialsInd.name}
            isRequired={PAPER_FIELDS.missingExamMaterialsInd.required}
          />
          <Input
            type="checkbox"
            label="Misconduct (Select any that apply)"
            input={{
              name: SHARED_FIELDS.misconductInd.name,
              checked: currentValues[SHARED_FIELDS.misconductInd.name],
              onChange: e => {
                const { checked } = e.target
                change(SHARED_FIELDS.misconductInd.name, checked)
                if (!checked) {
                  change(SHARED_FIELDS.studentDismissedInd.name, false)
                  change(SHARED_FIELDS.usedProhibitedDeviceInd.name, false)
                  change(SHARED_FIELDS.removeExamMaterialsInd.name, false)
                }
              },
            }}
            name={SHARED_FIELDS.misconductInd.name}
            isRequired={SHARED_FIELDS.misconductInd.required}
          />
          <Input
            type="checkbox"
            label="Student Dismissed"
            name={SHARED_FIELDS.studentDismissedInd.name}
            isRequired={SHARED_FIELDS.studentDismissedInd.required}
            disabled={!currentValues[SHARED_FIELDS.misconductInd.name]}
            style={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Student Observed with a Phone of Any Kind/Prohibited Electronic Device or It Made Noise"
            name={SHARED_FIELDS.usedProhibitedDeviceInd.name}
            isRequired={SHARED_FIELDS.usedProhibitedDeviceInd.required}
            disabled={!currentValues[SHARED_FIELDS.misconductInd.name]}
            style={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Student removed/attempted to remove exam materials"
            name={SHARED_FIELDS.removeExamMaterialsInd.name}
            isRequired={SHARED_FIELDS.removeExamMaterialsInd.required}
            disabled={!currentValues[SHARED_FIELDS.misconductInd.name]}
            style={childControlStyles}
          />
        </div>
      </div>
      <div className="col-sm-6 col-xs-12">
        <div style={{ marginBottom: '24px' }}>
          <InlineText
            name={PAPER_FIELDS.overtimingMinutes.name}
            validate={
              currentValues[PAPER_FIELDS.overtimingMinutesInd.name]
                ? [RequiredValidation, PositiveNumberValidation, PositiveMaximumNumberValidation]
                : null
            }
            label=""
            placeholder="Enter number of minutes of overtiming"
            controlInput={{
              type: 'checkbox',
              name: PAPER_FIELDS.overtimingMinutesInd.name,
              label: 'Overtiming-Number of Minutes',
              input: {
                onChange: e => {
                  const { checked } = e.target
                  change(PAPER_FIELDS.overtimingMinutesInd.name, checked)
                  if (!checked) {
                    change(PAPER_FIELDS.overtimingMinutes.name, null)
                  } else {
                    setTimeout(
                      () => document.getElementById(PAPER_FIELDS.overtimingMinutes.name).focus(),
                      50
                    )
                  }
                },
              },
            }}
            disabled={!currentValues[PAPER_FIELDS.overtimingMinutesInd.name]}
            isRequired={currentValues[PAPER_FIELDS.overtimingMinutesInd.name]}
            maxlength={4}
            withFlexbox={false}
            fullWidthError={true}
            textboxGroupStyles={childControlStyles}
          />
          <InlineText
            name={PAPER_FIELDS.undertimingMinutes.name}
            validate={
              currentValues[PAPER_FIELDS.undertimingMinutesInd.name]
                ? [RequiredValidation, PositiveNumberValidation, PositiveMaximumNumberValidation]
                : null
            }
            label=""
            placeholder="Enter number of minutes of undertiming"
            controlInput={{
              type: 'checkbox',
              name: PAPER_FIELDS.undertimingMinutesInd.name,
              label: 'Undertiming-Number of Minutes',
              input: {
                onChange: e => {
                  const { checked } = e.target
                  change(PAPER_FIELDS.undertimingMinutesInd.name, checked)
                  if (!checked) {
                    change(PAPER_FIELDS.undertimingMinutes.name, null)
                  } else {
                    setTimeout(
                      () => document.getElementById(PAPER_FIELDS.undertimingMinutes.name).focus(),
                      50
                    )
                  }
                },
              },
            }}
            disabled={!currentValues[PAPER_FIELDS.undertimingMinutesInd.name]}
            isRequired={currentValues[PAPER_FIELDS.undertimingMinutesInd.name]}
            maxlength={4}
            withFlexbox={false}
            fullWidthError={true}
            textboxGroupStyles={childControlStyles}
          />
          <InlineText
            name={SHARED_FIELDS.interruptionMinutes.name}
            validate={
              currentValues[SHARED_FIELDS.interruptionMinutesInd.name]
                ? [RequiredValidation, PositiveNumberValidation, PositiveMaximumNumberValidation]
                : null
            }
            label=""
            placeholder="Enter number of minutes of interruption"
            controlInput={{
              type: 'checkbox',
              name: SHARED_FIELDS.interruptionMinutesInd.name,
              label: 'Interruption-Number of Minutes',
              input: {
                onChange: e => {
                  const { checked } = e.target
                  change(SHARED_FIELDS.interruptionMinutesInd.name, checked)
                  if (!checked) {
                    change(SHARED_FIELDS.interruptionMinutes.name, null)
                  } else {
                    setTimeout(
                      () => document.getElementById(SHARED_FIELDS.interruptionMinutes.name).focus(),
                      50
                    )
                  }
                },
              },
            }}
            disabled={!currentValues[SHARED_FIELDS.interruptionMinutesInd.name]}
            isRequired={currentValues[SHARED_FIELDS.interruptionMinutesInd.name]}
            maxlength={4}
            withFlexbox={false}
            fullWidthError={true}
            textboxGroupStyles={childControlStyles}
          />
          <InlineText
            name={SHARED_FIELDS.disturbanceMinutes.name}
            validate={
              currentValues[SHARED_FIELDS.disturbanceMinutesInd.name]
                ? [RequiredValidation, PositiveNumberValidation, PositiveMaximumNumberValidation]
                : null
            }
            label=""
            placeholder="Enter number of minutes of disturbance"
            controlInput={{
              type: 'checkbox',
              name: SHARED_FIELDS.disturbanceMinutesInd.name,
              label: 'Disturbance-Number of Minutes',
              input: {
                onChange: e => {
                  const { checked } = e.target
                  change(SHARED_FIELDS.disturbanceMinutesInd.name, checked)
                  if (!checked) {
                    change(SHARED_FIELDS.disturbanceMinutes.name, null)
                  } else {
                    setTimeout(
                      () => document.getElementById(SHARED_FIELDS.disturbanceMinutes.name).focus(),
                      50
                    )
                  }
                },
              },
            }}
            disabled={!currentValues[SHARED_FIELDS.disturbanceMinutesInd.name]}
            isRequired={currentValues[SHARED_FIELDS.disturbanceMinutesInd.name]}
            maxlength={4}
            withFlexbox={false}
            fullWidthError={true}
            textboxGroupStyles={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Student Used Ink on Answer Sheet"
            name={PAPER_FIELDS.usedInkOnAnswerSheetInd.name}
            isRequired={PAPER_FIELDS.usedInkOnAnswerSheetInd.required}
          />
          <Input
            type="checkbox"
            label="Student Used Extra Paper"
            name={PAPER_FIELDS.usedExtraPaperInd.name}
            isRequired={PAPER_FIELDS.usedExtraPaperInd.required}
          />
          <Input
            type="checkbox"
            label="Student Shared Same AP ID"
            name={PAPER_FIELDS.sharedSameAPIDInd.name}
            isRequired={PAPER_FIELDS.sharedSameAPIDInd.required}
          />
          <Input
            type="checkbox"
            label="Testing Off-Schedule"
            name={PAPER_FIELDS.testingOffScheduleInd.name}
            isRequired={PAPER_FIELDS.testingOffScheduleInd.required}
          />
          <Input
            type="checkbox"
            label="Other"
            name={SHARED_FIELDS.otherInd.name}
            isRequired={SHARED_FIELDS.otherInd.required}
          />
        </div>
      </div>
    </div>
  )
}

export default IncidentReportPaperIncidentFields
