import { Modal, Error, BasicInput, BasicSelect } from '../../../common'
import UpdateTable from '../../../orders/coordinator/UpdateTable'
import SelectNotTakingExamReason from '../../common/SelectNotTakingExamReason'
import { openModal } from '../../../../actions/app'

import style from '../../../../assets/style/scss/orders.scss'

const mapStateToProps = (state, { exam }) => {
  const {
    studentsByOrg: { courseMap, exams },
    status: {
      data: { isSubmitted },
    },
  } = state
  const swappableEnrollments = exams.reduce((acc, e) => {
    return exam.swapWithEnrollmentIds.find(s => s === e.enrollmentId)
      ? [...acc, { value: e.enrollmentId, label: courseMap[e.testCd].name }]
      : acc
  }, [])

  return {
    swappableEnrollments,
    isSubmitted,
  }
}

class SwapExamModal extends Component {
  constructor(props) {
    const { swappableEnrollments } = props
    super(props)
    this.hasMultiOptions = swappableEnrollments.length > 1
    this.state = {
      swapExams: true,
      notReasonObj: {},
      swapExamEnrollmentId: this.hasMultiOptions ? null : swappableEnrollments[0].value,
    }

    this.getUpdatedExamIntent = this.getUpdatedExamIntent.bind(this)
    this.onCloseAction = this.onCloseAction.bind(this)
    this.onRadioChange = this.onRadioChange.bind(this)
  }

  onRadioChange(e) {
    const { notReasonObj } = this.state
    const { swappableEnrollments } = this.props
    const selected = e.target.value === 'true'
    this.setState({
      swapExams: selected,
      notReasonObj: selected ? null : notReasonObj,
      swapExamEnrollmentId: this.hasMultiOptions ? null : swappableEnrollments[0].value,
    })
  }

  onCloseAction() {
    const { onCloseAction, swappableEnrollments, isSubmitted } = this.props
    const { swapExamEnrollmentId } = this.state

    if (isSubmitted)
      onCloseAction({
        swapCourseName: swapExamEnrollmentId
          ? swappableEnrollments.find(s => s.value === swapExamEnrollmentId)?.label
          : null,
      })
  }

  getTitles() {
    const { isSubmitted, swappableEnrollments } = this.props

    return {
      header: isSubmitted ? 'Change Submitted Order' : 'Changing AP Art and Design Exams',
      legend: this.hasMultiOptions ? (
        'Would you like to order another AP Art and Design exam?'
      ) : (
        <>
          Would you like to order the <strong>{swappableEnrollments[0].label}</strong> exam?
        </>
      ),
    }
  }

  getInput(label, ariaLabel, value, onChange) {
    const { swapExams } = this.state
    const yesRadioStyle = this.hasMultiOptions
      ? { float: 'left', lineHeight: '48px', marginRight: 15 }
      : {}
    const noRadioStyle = this.hasMultiOptions ? { clear: 'left' } : {}

    return (
      <BasicInput
        input={{ name: 'swapExam', onChange, value }}
        type="radio"
        label={label}
        ariaLabel={ariaLabel}
        checked={value ? swapExams : !swapExams}
        style={value ? yesRadioStyle : noRadioStyle}
      />
    )
  }

  getSelect() {
    const { swappableEnrollments } = this.props
    const { swapExams, swapExamEnrollmentId } = this.state
    return this.hasMultiOptions ? (
      <BasicSelect
        options={swappableEnrollments}
        input={{
          name: 'swapExamOptions',
          onChange: e => this.setState({ swapExamEnrollmentId: parseInt(e.target.value, 10) }),
          value: swapExamEnrollmentId,
        }}
        disabled={!swapExams}
        style={{ float: 'left', marginBottom: 5, width: '50%' }}
      />
    ) : null
  }

  getUpdatedExamIntent() {
    const { exam } = this.props
    const { notReasonObj, swapExamEnrollmentId } = this.state
    return {
      ...notReasonObj,
      ...exam,
      swapExamEnrollmentId,
    }
  }

  showCostTableAndNotTakingReasons() {
    const { isSubmitted, exam, studentName, courseName } = this.props
    return (
      <>
        <hr />
        <p>
          You are indicating that the student below will not take the exam for{' '}
          <strong>{courseName}</strong>. The cost for this exam is noted below.
        </p>
        <SelectNotTakingExamReason onChangeAction={obj => this.setState({ notReasonObj: obj })} />
        <hr className={style['order-border-warning']} />
        {isSubmitted ? (
          <p>You will need to review and submit your change order to apply this update.</p>
        ) : null}
        <UpdateTable exam={exam} studentName={studentName} />
      </>
    )
  }

  render() {
    const {
      modalStyles,
      courseName,
      swappableEnrollments,
      modalCloseFocusElem,
      error,
      shouldCloseModal,
      getFooterActions,
    } = this.props
    const { header, legend } = this.getTitles()
    const { swapExams, swapExamEnrollmentId } = this.state
    const formNotFilledOut = swapExams && !swapExamEnrollmentId
    const singleSwap = !this.hasMultiOptions
    const getInputText = (
      <>
        Yes, order the {singleSwap ? <strong>{swappableEnrollments[0].label}</strong> : 'following'}{' '}
        exam for this student and drop the <strong>{courseName}</strong> exam
      </>
    )
    const ariaLabelText = `Yes, order the ${
      singleSwap ? swappableEnrollments[0].label : 'following'
    } exam for this student and drop the ${courseName} exam`

    return (
      <Modal
        modalStyles={modalStyles}
        headerTitle={header}
        shouldCloseModal={shouldCloseModal}
        onCloseAction={this.onCloseAction}
        modalCloseFocusElem={modalCloseFocusElem}
        footerActions={getFooterActions(this.getUpdatedExamIntent, { disabled: formNotFilledOut })}
      >
        {error ? <Error title="Error Updating Student" message={error} /> : null}
        <p>
          You are removing <strong>{courseName}</strong>. You can switch this student to{' '}
          {singleSwap ? (
            <>
              an <strong>{swappableEnrollments[0].label}</strong>
            </>
          ) : (
            <>another AP Art and Design</>
          )}{' '}
          exam without an additional fee.
        </p>
        <form style={{ marginBottom: 20 }}>
          <fieldset>
            <legend className="cb-base-font">{legend}</legend>
            <>
              {this.getInput(getInputText, ariaLabelText, true, this.onRadioChange)}
              {this.getSelect()}
            </>
            {this.getInput(
              <>
                No, drop the <strong>{courseName}</strong> exam
              </>,
              `No, drop the ${courseName} exam`,
              false,
              e => this.setState({ swapExams: false, swapExamEnrollmentId: null })
            )}
          </fieldset>
        </form>
        {!swapExams ? this.showCostTableAndNotTakingReasons() : null}
      </Modal>
    )
  }
}

export default connect(
  mapStateToProps,
  { openModal }
)(SwapExamModal)
