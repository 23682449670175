export const AP_ID_LABELS = 'AP ID Labels'
export const ADMIN_WINDOW_HEADER_SUFFIX = 'Testing Materials'
export const SCORE_REPORTING_SERVICE = 'Score Reporting Service'

export const EXAM_NAME = 'Exam Name'
export const ANCILLARY_MATERIALS = 'Ancillary Materials'

// For display
export const SHIPPED = 'Shipped'
export const PROCESSING = 'Processing'
export const SUBMITTED = 'Submitted'
export const RECEIVED = 'Received'

// For comparison
export const STATUS_SHIPPED = 'SHIPPED'
export const STATUS_PROCESSING = 'PROCESSING'
export const STATUS_SUBMITTED = 'SUBMITTED'
export const STATUS_RECEIVED = 'RECEIVED'

export const ALL_ADMIN = 'ALL_ADMIN'
export const NONSTOCK = 'NONSTOCK'

/**
 * Tracking numbers
 */

export const CARRIER_DHL = 'DHL'
export const CARRIER_EXPRESS = 'Express Mail'
export const CARRIER_NASSAU = 'Nassau Courier'
export const CARRIER_PRIORITY_SOLUTIONS = 'Priority Air Express'
export const CARRIER_UPS = 'UPS'
export const CARRIER_UPS_FREIGHT = 'UPS Freight'
export const CARRIER_USPS = 'USPS'

export const trackingLink = (company, tracking) => {
  const shipmentUrls = {
    [CARRIER_DHL]: `http://www.dhl.com/en/express/tracking.html?AWB=${tracking}&brand=DHL`,
    [CARRIER_EXPRESS]: `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking}`,
    [CARRIER_PRIORITY_SOLUTIONS]:
      'https://customer.prioritysolutions.com/Tracepro3/SignInPage.aspx?108A4518-F231-4867-9870-6F699C47D2A4',
    [CARRIER_UPS]: 'https://www.ups.com/track?loc=en_US&requester=WT/',
    [CARRIER_UPS_FREIGHT]: 'https://ltl.upsfreight.com/shipping/tracking/Index.aspx',
    [CARRIER_USPS]: `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking}`,
  }

  return shipmentUrls[company]
}

export const VENDOR_LIST = [
  CARRIER_DHL,
  CARRIER_EXPRESS,
  CARRIER_NASSAU,
  CARRIER_PRIORITY_SOLUTIONS,
  CARRIER_UPS,
  CARRIER_UPS_FREIGHT,
  CARRIER_USPS,
]

/**
 * Display constants
 */
export const VENDOR_DISPLAY = {
  [CARRIER_DHL]: 'DHL',
  [CARRIER_EXPRESS]: 'USPS - Priority Mail Express',
  [CARRIER_NASSAU]: 'Nassau Courier',
  [CARRIER_PRIORITY_SOLUTIONS]: 'Priority Air Express',
  [CARRIER_UPS]: 'UPS',
  [CARRIER_UPS_FREIGHT]: 'UPS Freight',
  [CARRIER_USPS]: 'USPS',
}

export const noNumber = 'There is no tracking number available.'
export const noInfo = 'No tracking info available.'
export const notify = 'You will be notified when the package has shipped.'

/**
 * Reducer Ordering Objects
 */
export const TYPE_AP_LABELS = 'AP_LABELS'
export const TYPE_ALL_ADMIN = 'ALL_ADMIN'

export const TYPE_LABELS = {
  [TYPE_AP_LABELS]: 'AP ID Labels',
  [TYPE_ALL_ADMIN]: 'Score Reporting Services',
}

export const SECTION_EXAM_MATERIALS = 'EXAM_MATERIALS'
export const SECTION_STUDIO_ART = 'STUDIO_ART'
export const SECTION_EXAM_WITH_SPECIAL_MATERIALS = 'EXAM_WITH_SPECIAL_MATERIALS'
export const SECTION_EXAM_WITH_SPECIAL_MATERIALS_NONSTOCK = 'EXAM_WITH_SPECIAL_MATERIALS_NONSTOCK'
export const SECTION_CHINESE_JAPANESE_SETUP_CDS = 'CHINESE/JAPANESE_SETUP_CDS'
export const SECTION_ANCILLARY_MATERIALS = 'ANCILLARY_MATERIALS'

export const SHIP_STANDARD = 'STANDARD'
export const SHIP_LATE = 'LATE'
export const SHIP_EXCEPTION = 'EXCEPTION'
export const SHIP_OFFCYCLE = 'OFFCYCLE'

export const SHIPMENT_ORDER = [
  TYPE_AP_LABELS,
  SHIP_STANDARD,
  SHIP_LATE,
  SHIP_EXCEPTION,
  SHIP_OFFCYCLE,
  TYPE_ALL_ADMIN,
]

export const SECTIONS = [
  SECTION_EXAM_MATERIALS,
  SECTION_STUDIO_ART,
  SECTION_EXAM_WITH_SPECIAL_MATERIALS,
  SECTION_EXAM_WITH_SPECIAL_MATERIALS_NONSTOCK,
  SECTION_CHINESE_JAPANESE_SETUP_CDS,
  SECTION_ANCILLARY_MATERIALS,
]

export const MATERIAL_TYPES = [SECTION_EXAM_MATERIALS, SECTION_ANCILLARY_MATERIALS]

export const CONTENT = {
  [SECTION_EXAM_MATERIALS]: 'Exam Materials',
  [SECTION_STUDIO_ART]: 'Studio Art Portfolios',
  [SECTION_EXAM_WITH_SPECIAL_MATERIALS]: 'Exam with Special Materials',
  [SECTION_EXAM_WITH_SPECIAL_MATERIALS_NONSTOCK]: 'Exam with Special Materials',
  [SECTION_CHINESE_JAPANESE_SETUP_CDS]: 'Chinese/Japanese Setup CDs',
  [SECTION_ANCILLARY_MATERIALS]: 'Additional Ancillary Materials',
}

export const SCORE_FREE_RESPONSE_BOOKLETS = 'FREE_RESPONSE_BOOKLETS'
export const SCORE_AP_SCORE_LABELS = 'AP_SCORE_LABELS'

export const SCORE_SECTIONS = [SCORE_FREE_RESPONSE_BOOKLETS, SCORE_AP_SCORE_LABELS]

export const SCORE_CONTENT = {
  [SCORE_FREE_RESPONSE_BOOKLETS]: 'Free-Response Booklets',
  [SCORE_AP_SCORE_LABELS]: 'AP Score Labels',
}

export const TYPE_MAIN = 'MAIN'
export const TYPE_SUPPLEMENTAL = 'SUPPLEMENTAL'

export const TYPE = {
  [TYPE_MAIN]: 'Main',
  [TYPE_SUPPLEMENTAL]: 'Supplemental',
}

export const SUBTYPES = [TYPE_MAIN, TYPE_SUPPLEMENTAL]

/**
 * Actions
 */
export const FETCH_SHIPMENTS_SUMMARY_FULFILLED = 'FETCH_SHIPMENTS_SUMMARY_FULFILLED'
export const FETCH_SHIPMENTS_SUMMARY_PENDING = 'FETCH_SHIPMENTS_SUMMARY_PENDING'
export const FETCH_SHIPMENTS_SUMMARY_REJECTED = 'FETCH_SHIPMENTS_SUMMARY_REJECTED'

export const FETCH_SHIPMENTS_SUBORDER_FULFILLED = 'FETCH_SHIPMENTS_SUBORDER_FULFILLED'
export const FETCH_SHIPMENTS_SUBORDER_PENDING = 'FETCH_SHIPMENTS_SUBORDER_PENDING'
export const FETCH_SHIPMENTS_SUBORDER_REJECTED = 'FETCH_SHIPMENTS_SUBORDER_REJECTED'

export const FETCH_SHIPPING_ADDRESS_PENDING = 'FETCH_SHIPPING_ADDRESS_PENDING'
export const FETCH_SHIPPING_ADDRESS_FULFILLED = 'FETCH_SHIPPING_ADDRESS_FULFILLED'
export const FETCH_SHIPPING_ADDRESS_REJECTED = 'FETCH_SHIPPING_ADDRESS_REJECTED'
