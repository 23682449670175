export const SUBMIT_INCIDENT_REPORT_PENDING = 'SUBMIT_INCIDENT_REPORT_PENDING'
export const SUBMIT_INCIDENT_REPORT_FULFILLED = 'SUBMIT_INCIDENT_REPORT_FULFILLED'
export const SUBMIT_INCIDENT_REPORT_REJECTED = 'SUBMIT_INCIDENT_REPORT_REJECTED'

export const FETCH_INCIDENT_REPORT_DRAFT_PENDING = 'FETCH_INCIDENT_REPORT_DRAFT_PENDING'
export const FETCH_INCIDENT_REPORT_DRAFT_FULFILLED = 'FETCH_INCIDENT_REPORT_DRAFT_FULFILLED'
export const FETCH_INCIDENT_REPORT_DRAFT_REJECTED = 'FETCH_INCIDENT_REPORT_DRAFT_REJECTED'

export const FETCH_INCIDENT_REPORT_HISTORY_PENDING = 'FETCH_INCIDENT_REPORT_HISTORY_PENDING'
export const FETCH_INCIDENT_REPORT_HISTORY_FULFILLED = 'FETCH_INCIDENT_REPORT_HISTORY_FULFILLED'
export const FETCH_INCIDENT_REPORT_HISTORY_REJECTED = 'FETCH_INCIDENT_REPORT_HISTORY_REJECTED'

export const RESET_INCIDENT_REPORT = 'RESET_INCIDENT_REPORT'

// Courses for which Exam Form section should be hidden
// - 87 = Chinese Language and Culture
// - 88 = Japanese Language and Culture
// - 3 = Drawing
// - 4 = 2-D Art and Design
// - 5 = 3-D Art and Design
export const COURSES_WITH_HIDDEN_EXAM_FORM_SECTION = [87, 88, 3, 4, 5]

export const INCIDENT_REPORT_FORM_NAME = 'incidentReportForm'
export const INCIDENT_REPORT_SHARED_FIELD_NAMES = {
  firstName: { name: 'firstName', required: true },
  lastName: { name: 'lastName', required: true },
  phone: { name: 'phone', required: true },
  email: { name: 'email', required: true },
  section: { name: 'examSection', required: true, default: '' },
  testCode: { name: 'testCd', required: true, default: null },
  examDate: { name: 'adminWindow', required: true, default: null },
  examForm: { name: 'form', required: true, default: '' },
  formCode: { name: 'formCode', required: true, default: '' },
  interruptionMinutesInd: { name: 'interruptionMinutesInd', required: false, default: false },
  interruptionMinutes: { name: 'interruptionMinutes', required: false, default: null },
  disturbanceMinutesInd: { name: 'disturbanceMinutesInd', required: false, default: false },
  disturbanceMinutes: { name: 'disturbanceMinutes', required: false, default: null },
  misconductInd: { name: 'misconductInd', required: false, default: false },
  studentDismissedInd: { name: 'studentDismissedInd', required: false, default: false },
  usedProhibitedDeviceInd: { name: 'usedProhibitedDeviceInd', required: false, default: false },
  removeExamMaterialsInd: { name: 'removeExamMaterialsInd', required: false, default: false },
  illnessInd: { name: 'illnessInd', required: false, default: false },
  otherInd: { name: 'otherInd', required: false, default: false },
  other: { name: 'other', required: false, default: false }, // needed for viewing old IRs
  studentFirstName: { name: 'studentFirstName', required: false, default: '' },
  studentLastName: { name: 'studentLastName', required: false, default: '' },
  studentApId: { name: 'apId', required: false, default: '' },
  incidentDetail: { name: 'incidentDetail', default: null },
  apServicesCaseNumber: { name: 'apServicesCaseNumber', required: false, default: null }, // needed for viewing old IRs
  lateTestingRequiredInd: { name: 'lateTestingRequiredInd', required: false, default: false },
  altExamOrderedInd: { name: 'altExamOrderedInd', required: false, default: false },
  fullTestCenterImpactedInd: { name: 'fullTestCenterImpactedInd', required: false, default: false },
  students: { name: 'students', required: false, default: [] },
}
export const INCIDENT_REPORT_PAPER_FIELD_NAMES = {
  defectiveMatsInd: { name: 'defectiveMaterialsInd', required: false, default: false },
  multipleChoiceInd: { name: 'multipleChoiceInd', required: false, default: false },
  freeResponseInd: { name: 'freeResponseInd', required: false, default: false },
  shortAnswerResponseInd: { name: 'shortAnswerResponseInd', required: false, default: false },
  orangeBookletInd: { name: 'orangeBookletInd', required: false, default: false },
  masterAudioCDsInd: { name: 'masterAudioCDsInd', required: false, default: false },
  masterAudioCDs: { name: 'masterAudioCDs', required: false, default: null },
  equipProbsInd: { name: 'equipmentProblemsInd', required: false, default: false },
  shortAnsrWrngBookInd: {
    name: 'shortAnsFreeRespWrittenInWrongBookInd',
    required: false,
    default: false,
  },
  earlyOpeningSectionInd: { name: 'earlyOpeningSectionInd', required: false, default: false },
  mixUpFreeResponseBooksInd: { name: 'mixUpFreeResponseBooksInd', required: false, default: false },
  wrngColorInkInd: {
    name: 'usedInappropriateInkColorInFreeResponseBookInd',
    required: false,
    default: false,
  },
  misplacedAnswersInd: { name: 'misplacedAnswersInd', required: false, default: false },
  missingExamMaterialsInd: { name: 'missingExamMaterialsInd', required: false, default: false },
  overtimingMinutesInd: { name: 'overtimingMinutesInd', required: false, default: false },
  overtimingMinutes: { name: 'overtimingMinutes', required: false, default: null },
  undertimingMinutesInd: { name: 'undertimingMinutesInd', required: false, default: false },
  undertimingMinutes: { name: 'undertimingMinutes', required: false, default: null },
  usedInkOnAnswerSheetInd: { name: 'usedInkOnAnswerSheetInd', required: false, default: false },
  usedExtraPaperInd: { name: 'usedExtraPaperInd', required: false, default: false },
  sharedSameAPIDInd: { name: 'sharedSameAPIDInd', required: false, default: false },
  testingOffScheduleInd: { name: 'testingOffScheduleInd', required: false, default: false },
}

export const INCIDENT_REPORT_DIGITAL_FIELD_NAMES = {
  batteryDiedInd: { name: 'batteryDiedInd', required: false, default: false },
  connectionDroppedInd: { name: 'connectionDroppedInd', required: false, default: false },
  deviceFrozeOrCrashInd: { name: 'deviceFrozeOrCrashInd', required: false, default: false },
  deviceDidNotMeetReqInd: { name: 'deviceDidNotMeetReqInd', required: false, default: false },
  loanerDeviceIssueInd: { name: 'loanerDeviceIssueInd', required: false, default: false },
  anotherTestAppInstalledInd: {
    name: 'anotherTestAppInstalledInd',
    required: false,
    default: false,
  },
  blueBookNotInstalledInd: { name: 'blueBookNotInstalledInd', required: false, default: false },
  examSetUpNotCompletedInd: { name: 'examSetUpNotCompletedInd', required: false, default: false },
  noAdmissionTicketInd: { name: 'noAdmissionTicketInd', required: false, default: false },
  studentTechIssueOther: { name: 'studentTechIssueOther', required: false, default: false },
  couldNotOpenInd: { name: 'couldNotOpenInd', required: false, default: false },
  couldNotSignInd: { name: 'couldNotSignInd', required: false, default: false },
  testNotAvailableInd: { name: 'testNotAvailableInd', required: false, default: false },
  couldNotStartAfterTheStartCodeInd: {
    name: 'couldNotStartAfterTheStartCodeInd',
    required: false,
    default: false,
  },
  inAccurateTimingInd: { name: 'inAccurateTimingInd', required: false, default: false },
  contentDisplayIssueInd: { name: 'contentDisplayIssueInd', required: false, default: false },
  calculatorClockOrToolIssueInd: {
    name: 'calculatorClockOrToolIssueInd',
    required: false,
    default: false,
  },
  answerNotSubmittedInd: { name: 'answerNotSubmittedInd', required: false, default: false },
  blueBookProblemOther: { name: 'blueBookProblemOther', required: false, default: false },
  staffTechIssue: { name: 'staffTechIssue', required: false, default: false },
  networkOutageInd: { name: 'networkOutageInd', required: false, default: false },
  powerOutageInd: { name: 'powerOutageInd', required: false, default: false },
  facilityTechIssueOther: { name: 'facilityTechIssueOther', required: false, default: false },
  couldNotAccessTDTKInd: { name: 'couldNotAccessTDTKInd', required: false, default: false },
  inAccurateStudentRosterDataInd: {
    name: 'inAccurateStudentRosterDataInd',
    required: false,
    default: false,
  },
  studentCheckInIssueInd: { name: 'studentCheckInIssueInd', required: false, default: false },
  inAccurateStudentStatusInd: {
    name: 'inAccurateStudentStatusInd',
    required: false,
    default: false,
  },
  testDayToolKitProblemOther: {
    name: 'testDayToolKitProblemOther',
    required: false,
    default: false,
  },
}
