import { addFetchAction, removeFetchAction } from '../../../actions/app'
import { fetchFreeResponseBookletsForDownload } from '../../../actions/orders'
import DownloadFreeResponseBookletRow from './DownloadFreeResponseBookletRow'
import { Error, Loader } from '../../common'
import { sortColumnByKey } from '../../../utils/sort'

const mapStateToProps = state => {
  const {
    frbDownloads: { fetchingFRBs, fetchedFRBs, data = [], error },
  } = state

  return {
    fetchingFRBs,
    fetchedFRBs,
    data: sortColumnByKey(data, ['courseName'], ['asc']),
    error,
  }
}

const DownloadFreeResponseBooklets = ({
  fetchingFRBs,
  fetchedFRBs,
  data,
  error,
  fetchFreeResponseBookletsForDownload,
  addFetchAction,
  removeFetchAction,
}) => {
  useEffect(() => {
    addFetchAction({
      name: 'fetchFreeResponseBookletsForDownload',
      action: (selectedOrgId, selectedEducationPeriod) =>
        fetchFreeResponseBookletsForDownload(selectedOrgId, selectedEducationPeriod),
    })
    return () => {
      removeFetchAction('fetchFreeResponseBookletsForDownload')
    }
  }, [])

  if (error) {
    return <Error title="Error Retrieving Data" message={error} />
  }

  if (fetchingFRBs) {
    return <Loader />
  }

  if (fetchedFRBs) {
    if (data?.length > 0) {
      return (
        <div>
          {data.map(frb => (
            <DownloadFreeResponseBookletRow key={`${frb.testCd}_${frb.examFormat}`} {...frb} />
          ))}
        </div>
      )
    }
    return <p>No free-response booklets available for download at this time.</p>
  }

  return null
}

export default connect(mapStateToProps, {
  fetchFreeResponseBookletsForDownload,
  addFetchAction,
  removeFetchAction,
})(DownloadFreeResponseBooklets)
