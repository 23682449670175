import { reduxForm, getFormValues, change, hasSubmitFailed, getFormError } from 'redux-form'
import {
  CourseScheduleWithExamOnlyOption,
  CourseScheduleSelector,
  CoTeacherSelector,
  CourseSelector,
  OrgSelector,
} from '../../components/section'
import { createOrEditSection } from '../../actions/section'
import {
  Text,
  PositiveNumberValidation,
  LimitedStringValidation,
  CheckNotLessThanEnrollmentCount,
  Error,
  LegendLabel,
  Input,
  Select,
} from '../../components/common'
import {
  CREATE_FORM_NAME,
  CREATE_FORM_FIELDS as FIELDS,
  CREATE_FORM_SCHEDULE_LABEL_ID,
  CREATE_FORM_EXAM_ONLY_VALUE,
  CREATE_FORM_COURSE_SCHEDULE_OPTIONS,
  CREDIT_TYPE_OPTIONS,
} from '../../constants/SectionConstants'
import {
  isAPCourse,
  isCareerKickstartCourse,
  isPreAPCourse,
  getAllCourses,
} from '../../selectors/course'
import { isEmpty } from '../../utils/common'

const SEMINAR = 94

const mapStateToProps = (state, { section, testCd = null, orgId, creating }) => {
  const currentFormValues = getFormValues(CREATE_FORM_NAME)(state) ?? {}
  const {
    teacher: { fetching: fetchingTeachers, data: teacherData },
    settingsEducationPeriod: { selectedEducationPeriod, selectedIsCurrent },
    user: {
      data: { roles, selectedRole, isCoordinator, isLevelOne },
    },
  } = state

  const allAvailableCourses = getAllCourses({ state })

  const initialValues = section || {
    [FIELDS.testCd]: testCd,
    [FIELDS.orgId]: orgId,
    [FIELDS.edPeriod]: selectedEducationPeriod,
    [FIELDS.schedule]:
      isCoordinator && teacherData?.[orgId] && !teacherData?.[orgId]?.[testCd]?.length
        ? CREATE_FORM_EXAM_ONLY_VALUE
        : CREATE_FORM_COURSE_SCHEDULE_OPTIONS.find(c => c.isDefault).value,
    [FIELDS.teachers]: [],
    [FIELDS.students]: 40,
    ...(testCd === SEMINAR ? { [FIELDS.seminarCreditType]: null } : {}),
  }
  const selectedCourse = allAvailableCourses.find(c => c.testCd === testCd)
  const isAP = testCd && isAPCourse(selectedCourse)
  const isCK = testCd && isCareerKickstartCourse(selectedCourse)
  const isPreAP = testCd && isPreAPCourse(selectedCourse)

  return {
    currentFormValues,
    initialValues,
    testCd,
    orgId,
    fetchingTeachers,
    creating,
    hasAvailableTeachers: teacherData?.[orgId]?.[testCd]?.length > 0 ?? false,
    examOnlySelected: currentFormValues[FIELDS.schedule] === CREATE_FORM_EXAM_ONLY_VALUE,
    courseNeedsTeachers: testCd && (isPreAP || isLevelOne),
    showExamOnlyOption: testCd && (isAP || isCK) && !isLevelOne && isCoordinator,
    selectedIsCurrent,
    weServiceEligible: isAP && roles[selectedRole]?.weServiceAuthorized,
    submitFailed: hasSubmitFailed(CREATE_FORM_NAME)(state),
    error: getFormError(CREATE_FORM_NAME)(state),
  }
}

const CreateForm = props => {
  const formRef = useRef(null)
  const {
    orgId,
    testCd,
    selectedIsCurrent,
    creating,
    isCourseCreateMode,
    weServiceEligible,
    currentFormValues,
    submitFailed,
    error,
    fetchingTeachers,
    courseNeedsTeachers,
    hasAvailableTeachers,
    handleSubmit,
    examOnlySelected,
    showExamOnlyOption,
    initialValues,
    change,
  } = props
  const maxEnrollmentNumber = currentFormValues?.[FIELDS.students] ?? 0
  const weServiceSelected = currentFormValues?.[FIELDS.weService]
  const initialEnrollmentCount = initialValues?.enrollmentCount ?? 0

  useEffect(() => {
    if (submitFailed && error) {
      formRef.current.firstChild.focus()
    }
  }, [submitFailed, error])

  useEffect(() => {
    if (examOnlySelected && weServiceEligible && weServiceSelected) {
      change(FIELDS.weService, false)
    }
  }, [examOnlySelected, weServiceEligible, weServiceSelected])

  function checkErrorMessages() {
    let errorMessage = null
    let errorTitle = 'Cannot Create Section'

    if (submitFailed && error) {
      errorMessage = error
      errorTitle = null
    } else if (!fetchingTeachers && courseNeedsTeachers && !hasAvailableTeachers && orgId)
      errorMessage =
        'Error: You cannot create a section for a course that has no eligible teachers.'

    return errorMessage ? <Error title={errorTitle} message={errorMessage} /> : null
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      {checkErrorMessages()}
      <CourseSelector orgId={orgId} testCd={testCd} isCourseCreateMode={isCourseCreateMode} />
      <OrgSelector orgId={orgId} testCd={testCd} />
      <div style={!orgId || !testCd ? { display: 'none' } : {}}>
        <div className="row">
          <div className="col-sm-6">
            <Text
              name={FIELDS.name}
              isRequired={true}
              label="Section Name"
              maxlength="20"
              validate={[LimitedStringValidation]}
            />
          </div>
          <div className="col-sm-6">
            <Text
              name={FIELDS.students}
              isRequired={true}
              validate={[PositiveNumberValidation, CheckNotLessThanEnrollmentCount]}
              label="Maximum number of students"
              placeholder="Enter number..."
              maxlength="4"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <fieldset>
              <LegendLabel
                label="Course Schedule"
                isRequired={true}
                id={CREATE_FORM_SCHEDULE_LABEL_ID}
              />
              {showExamOnlyOption ? (
                <CourseScheduleWithExamOnlyOption
                  selectedIsCurrent={selectedIsCurrent}
                  examOnlySelected={examOnlySelected}
                  schedule={currentFormValues[FIELDS.schedule]}
                  disabled={initialEnrollmentCount || !hasAvailableTeachers}
                />
              ) : (
                <CourseScheduleSelector
                  selectedIsCurrent={selectedIsCurrent}
                  examOnlySelected={examOnlySelected}
                  schedule={currentFormValues[FIELDS.schedule]}
                  disabled={initialEnrollmentCount || !hasAvailableTeachers}
                />
              )}
              {initialEnrollmentCount ? (
                <p className="cb-small-font-size">
                  You cannot change the course schedule once students are enrolled.
                </p>
              ) : null}
            </fieldset>
          </div>
          <div className="col-sm-6">
            <CoTeacherSelector
              creating={creating}
              testCd={testCd}
              examOnlySelected={examOnlySelected}
            />
          </div>
        </div>
        {weServiceEligible || testCd === SEMINAR ? (
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              {weServiceEligible ? (
                <Input
                  type="checkbox"
                  style={{ marginTop: '8px' }}
                  disabled={examOnlySelected}
                  name={FIELDS.weService}
                  label="AP with WE Service"
                />
              ) : null}
              {testCd === SEMINAR ? (
                <Select
                  label="Credit Type"
                  options={CREDIT_TYPE_OPTIONS}
                  isRequired={!examOnlySelected}
                  name={FIELDS.seminarCreditType}
                  style={{ marginTop: '16px' }}
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </form>
  )
}

export default connect(mapStateToProps, { createOrEditSection, change })(
  reduxForm({
    form: CREATE_FORM_NAME,
    enableReinitialize: true,
    onSubmit: (values, dispatch, { createOrEditSection }) =>
      createOrEditSection({
        ...values,
        creditType: !isEmpty(values.creditType) ? values.creditType : undefined,
      }),
  })(CreateForm)
)
